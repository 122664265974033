import React, {useEffect, useState} from 'react'
import { useForm } from 'react-hook-form';
import "../assets/scss/changePassword.scss"
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Api } from '../libs/Api';
import ContactSuccess from './ContactSuccess';

export default function ChangePassword() {
    const [message,setMessage] = useState(null)
  const formSchema = Yup.object().shape({
    newPassword: Yup.string(),
    repeatPassword: Yup.string()
      .required("Պարտադիր է լրացնել")
      .oneOf([Yup.ref("newPassword")], "Գաղտնաբառերը չեն համապատասխանում")
  });
  useEffect(() => {
      window.scrollTo({top:0})
  },[])
  
  const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
      } = useForm({
        mode: "onTouched",
        resolver: yupResolver(formSchema)
      });

      const onSubmit = async (data) => {
        const oldPassword=data.oldPassword;
        const newPassword=data.newPassword;
          setMessage(null)
        Api.post('profile/change-password',{
          "old_password":oldPassword,
          "new_password":newPassword
        }).then(function(response){
            if(response.hasOwnProperty("formError")){
                setMessage(response.formError.old_password[0])
            } else {
                setMessage(null)
                window.popupSetter({open:true,body:<ContactSuccess text={"Ձեր գաղտնաբառը հաջողությամբ փոխվել է։"} />,
                    popupClassContainer:'popup__container--sm',
                    popupClassInfo:"popup__container__info--sm"})
                reset()
            }

        })
        .catch(function(error){
          console.log("error", error)
        })
      }
      
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="profileEmpthy">
        <h4 className='text-blue text-blue--lg personalInfo__title'>Փոխել գաղտնաբառը</h4>
        <div className='changePassword'>
        <div className="formInput">
                <label htmlFor="message" className="formInput__label">
                Հին գաղտնաբառ *
                </label>
                <input
                  className={`formInput__input`}
                  type="password"
                  {...register("oldPassword", {
                    required: "Պարտադիր է լրացնել",
                  })}
                />
                {errors.oldPassword && (
                  <span className="inputError" role="alert">
                    {errors.password.message}
                  </span>
                )}
        </div>
        <div className="formInput">
                <label htmlFor="message" className="formInput__label">
                 Նոր գաղտնաբառ *
                </label>
                <input
                  className={`formInput__input`}
                  type="password"
                  {...register("newPassword", {
                  })}
                />
                {errors.newPassword && (
                  <span className="inputError" role="alert">
                    {errors.password.message}
                  </span>
                )}
        </div>
        <div className="formInput">
                <label htmlFor="message" className="formInput__label">
                Կրկնել նոր գաղտնաբառը *
                </label>
                <input
                  className={`formInput__input`}
                  type="password"
                  {...register("repeatPassword", {
                  })}
                />
                {errors.repeatPassword && (
                  <span className="inputError" role="alert">
                    {errors.password?.message}
                  </span>
                )}
        </div>
            {errors.repeatPassword && <p className="inputAlert">{errors.repeatPassword?.message}</p>}
            {message && <p className="inputAlert">{message}</p>}
        </div>
        <input
          className="main-button main-button--blue main-button--lg changePassword__submit"
          type="submit"
          value="Պահպանել"
        />
    </form>
  )
}
