import React, {useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom';
import "../assets/scss/loanStatus.scss"
import "../assets/scss/profileLoans.scss"
import {Api} from '../libs/Api';
import CancelReasonPopup from './CancelReasonPopup';
import PopupSteps from './PopupSteps';
import checkExistingLoan from "../services/checkExistingLoan";


export default function ProfileLoans() {
    const [loans, setLoans] = useState([]);
    const [openPopup, setOpenPopup] = useState(false)
    const [isMobile, setIsMobile] = useState()


    const pageWidth = window.innerWidth

    const navigate = useNavigate()

    useEffect(() => {
        if (pageWidth < 750) {
            setIsMobile(true)
        }
    }, [pageWidth])

    useEffect(() => {
        Api.get(`profile/get-user-loans`, {}).then((data) => {
            setLoans(data)
        })
    }, []);

    const  openPopupSteps = async () => {
        if ( await checkExistingLoan()) {
            setOpenPopup(false);
        } else {
            setOpenPopup(true)
            document.body.style.overflow = 'hidden';
        }
    }
    const closePopup = () => {
        setOpenPopup(false)
        document.body.style.overflow = 'unset';
        window.popupSetter({open: true, body: <CancelReasonPopup/>})
    }
    const showDetails = (loan) =>{
        isMobile &&
        navigate('/details', {
            state: {
                "loanInfo":loan,
            }
        })
    }

    return (
        <div className='profileLoans'>
            <PopupSteps closePopup={closePopup} open={openPopup}/>
            <div className='profileLoans__top'>
                <h4 className='text-blue text-blue--lg'>Իմ վարկերը</h4>
                <div onClick={() => openPopupSteps()} className='main-button main-button--withBorder hide_sm'>Դիմել
                    վարկի համար
                </div>
            </div>
            <table className='profileLoans__table'>
                <tbody>
                <tr className='profileLoans__table__headingRow'>
                    <th>Գումար</th>
                    <th>Ժամկետ</th>
                    <th className={"hide_sm"}>Դիմումի օր/ժամ</th>
                    <th  className={"hide_sm"}>Տրվել է</th>
                    <th  className={"hide_sm"}>Մարման օր</th>
                    <th>Կարգավիճակ</th>
                    <th  className={"hide_sm"}>Պայմանագիր</th>
                </tr>
                {
                    loans.map((item) => (
                        <tr className='profileLoans__table__row' key={item.id} onClick={() => showDetails(item)}>
                            <td className='text-light text-light--gray'>{item.loan_size}</td>
                            <td className='text-light text-light--gray'>{item.loan_day_count} ամիս</td>
                            <td className='text-light text-light--gray hide_sm'>{item.create_date}</td>
                            <td className='text-light text-light--gray hide_sm'>{item.start_date ? item.start_date : "-"}</td>
                            <td className='text-light text-light--gray hide_sm'>{item.end_date ? item.end_date : "-"}</td>
                            <td>
                                <div
                                    className={`loanStatus loanStatus--${item.status}`}>{item.status_title}</div>
                            </td>
                            <td className='text-light text-light--gray hide_sm'>{item.agreement_number}</td>
                        </tr>
                    ))
                }
                </tbody>
            </table>
            <div className="show_sm profileLoans__bottom" >
                <div onClick={() => openPopupSteps()} className='main-button main-button--blue '>Դիմել
                    վարկի համար
                </div>
            </div>
        </div>


    )
}
