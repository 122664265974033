import React, {useEffect, useState} from 'react'
import "../assets/scss/firstStep.scss"
import amd from "../assets/img/formSteps/amd.svg"
import month from "../assets/img/formSteps/month.svg"
import {Api} from "../libs/Api";
import moment from "moment";
import "moment/locale/hy-am";
import {numberFormat} from "../libs/globalFunctions";
import LoanFormService from "../services/loanFormService";
import ReactSimpleRange from "react-simple-range";
import CancelReasonPopup from "./CancelReasonPopup";
import PopupBranches from "./PopupBranches";


export default function FirstStep(props) {

    const [sum, setSum] = useState(100000)
    const [months, setMonths] = useState(6)
    const [payInfo, setPayInfo] = useState({});
    const [loanConfigs, setLoanConfigs] = useState();
    const [loanInfo, setLoanInfo] = useState({});
    const [mobileView, setMobileView] = useState(false)
    const [interestRate, setInterestRate] = useState()

    let pageWidth = window.innerWidth


    const handleSumChange = (e) => setSum(e.value);
    const handleMonthChange = (e) => {
        setMonths(e.value);
        let value = (e.value - loanInfo.min_days) / (loanInfo.max_days - loanInfo.min_days) * 100;
    }

    useEffect(() => {
        Api.get('data/get-loan-config').then((data) => {
            let initialData = LoanFormService.getFormData('loanInfo');

            setLoanConfigs(data);
            if (initialData) {
                setMonths(initialData.months);
                setSum(initialData.sum);
                setLoanInfo(data[initialData.loan_id]);
            } else {
                setLoanInfo(data[4]);
            }
        })
    }, [])

    const data = [
        {
            description: "Դուք վերցնում եք",
            value: `${payInfo.sum} դրամ`
        },
        {
            description: "Մինչև (ներառյալ)",
            value: `${payInfo.endDate}`
        },
        {
            description: "Ամսական վճար",
            value: `${payInfo.paySum} դրամ`
        }
    ]

    const toggle = (i) => {
        let new_loan_data = loanConfigs[i];
        if (sum < new_loan_data.min) {
            setSum(new_loan_data.min)
        }
        if (sum > new_loan_data.max) {
            setSum(new_loan_data.max)
        }
        if (months < new_loan_data.min_days) {
            setMonths(new_loan_data.min_days);
        }
        if (months > new_loan_data.max_days) {
            setMonths(new_loan_data.max_days);
        }
        setLoanInfo(loanConfigs[i]);
    }

    useEffect(() => {
        calcReturnSum()
        switch (loanInfo.id) {
            case 4:
                setInterestRate("(59.97-88.25%)");
                break;
            case 6:
                setInterestRate("(44.8-62.2%)");
                break;
            case 7:
                setInterestRate("(24.5-28.1%)");
                break;
        }
    }, [loanInfo, sum, months])
    const orderType = [
        {id: 4, label: "Առանց գրավի"},
        {id: 6, label: "Գրավով"},
        {id: 7, label: "Ապառիկ"},

    ]
    useEffect(() => {
        if (pageWidth < 750) setMobileView(true)
    }, [pageWidth])

    function calcReturnSum() {
        var dpers = loanInfo.main_percent;
        var sport_percent = loanInfo.support_percent;
        dpers = dpers / 100;
        var paySum
        if(dpers !== 0){
            paySum = sum * dpers / (1 - (1 / Math.pow((1 + dpers), months))) + (0.01 * sport_percent * sum);
        }else{
            paySum = sum / months + (0.01 * sport_percent * sum);
        }
        var endDate = moment().add(months, 'M').locale("hy").format('MMMM DD, YYYY');
        setPayInfo({
            sum: numberFormat(sum),
            endDate: endDate,
            paySum: numberFormat(paySum)
        })
    }

    const goNext = () => {
        if(loanInfo.id == 7){
            window.popupSetter({open: true, body: <PopupBranches data={{loan_id: loanInfo.id, months: months, sum: sum}} changeStep = {() => props.setCurrentStep(2)} /> })
        }else {
            LoanFormService.setFormData('loanInfo', {
                loan_id: loanInfo.id,
                months: months,
                sum: sum
            })
            props.setCurrentStep(2);
        }

    }

    return (
        <div className='firstStepForm'>
            <div className='firstStep'>
                <div className='firstStep__content'>
                    <ul className='firstStep__btnList'>
                        {
                            orderType.map((item, i) => {
                                return <li key={item.id} onClick={() => toggle(item.id)}
                                           className={loanInfo.id === item.id ? 'main-button main-button--blue main-button--md' : ""}>{item.label}</li>
                            })
                        }
                    </ul>
                    {loanInfo.id && <div className='firstStep__center'>
                        <div className='firstStep__range'>
                            <span className='firstStep__range__title'>Գումար</span>
                            <div className='firstStep__range__row'>
                                <span>{numberFormat(sum)}</span>
                                <img src={amd} alt="amd icon"/>
                            </div>
                            <ReactSimpleRange
                                min={loanInfo.min}
                                max={loanInfo.max}
                                defaultValue={sum}
                                step={10000}
                                trackColor={"#2B3B57"}
                                sliderSize={2}
                                sliderColor={"#EAEBEE"}
                                thumbColor={"#EAEBEE"}
                                onChange={handleSumChange}
                                customThumb={
                                    <div className={"blob"}
                                    ></div>
                                }
                            />
                        </div>
                        <div className='firstStep__range'>
                            <span className='firstStep__range__title'>Ժամկետ</span>
                            <div className='firstStep__range__row'>
                                <span>{months}</span>
                                <img src={month} alt="amd icon"/>
                            </div>
                            <ReactSimpleRange
                                min={loanInfo.min_days}
                                max={loanInfo.max_days}
                                defaultValue={months}
                                trackColor={"#2B3B57"}
                                sliderSize={2}
                                sliderColor={"#EAEBEE"}
                                thumbColor={"#EAEBEE"}
                                onChange={handleMonthChange}
                                customThumb={
                                    <div className={"blob"}
                                    ></div>
                                }
                            />
                        </div>
                    </div>
                    }
                </div>
                <div className='firstStep__bottom'>
                    <div className='firstStep__bottom__info'>
                        {data.map((item, i) => {
                            return (<div key={i}>
                                <span className='text-light text-light--gray text-light--reg'>{item.description}</span>
                                <p className='text-lg'>{item.value}</p>
                            </div>)
                        })}
                    </div>
                    <div className='firstStep__bottom__text text-gray-xs'><b>ՈւՇԱԴՐՈՒԹՅՈՒՆ՝</b> ՏԵՂԵԿԱՑՆՈՒՄ ԵՆՔ, ՈՐ ԱՅՍ
                        ՎԱՐԿԱՏԵՍԱԿՆ ՈՒՆԻ ԲԱՐՁՐ ՏԱՐԵԿԱՆ ՓԱՍՏԱՑԻ
                        ՏՈԿՈՍԱԴՐՈՒՅՔ <b>{interestRate}</b>: Ուստի, ՆԱԽԱՊԵՍ
                        ԴԻՏԱՐԿԵՔ
                        ՇՈՒԿԱՅՈՒՄ ԱՌԿԱ ՆՄԱՆԱՏԻՊ ԱՅԼ ԾԱՌԱՅՈՒԹՅՈՒՆՆԵՐԸ ԵՎ ԳՆԱՀԱՏԵՔ ՎԱՐԿԸ ՄԱՐԵԼՈՒ ՁԵՐ ՀՆԱՐԱՎՈՐՈՒԹՅՈՒՆՆԵՐԸ:
                    </div>
                </div>
            </div>
            <div className='popup-bottom'>
                <span className='text-blue-sm text-blue-sm--pointer text-blue-sm--gray'
                      onClick={() => props.closePopup()}>Չեղարկել</span>
                {mobileView ? <span className='main-button--mobile mobileBtnGradient' onClick={goNext}/> :
                    <span className='main-button main-button--xl-md main-button--gradient popup-bottom__button'
                          onClick={goNext}>Շարունակել</span>}
            </div>
        </div>
    )
}
