import React, {useEffect, useState} from 'react'
import "../assets/scss/sidebar.scss"
import {Outlet} from "react-router-dom";
import {Link} from 'react-router-dom';
import ExitConfirmPopup from "./ExitConfirmPopup";

export default function Profile() {
    const [active, setActive] = useState( {
        name: "Իմ վարկերը",
        class: "loans",
        id: 1,
        route: "loans"
    });
    const [isMobile, setIsMobile] = useState()
    const [closeIcon,setCloseIcon] = useState(false)

    const pageWidth = window.innerWidth

    useEffect(() => {
        if (pageWidth < 750) {
            setIsMobile(true)
            document.querySelector(".sidebar__list").style.padding = "30px"
        }
    }, [pageWidth])

    const data = [
        {
            name: "Իմ վարկերը",
            class: "loans",
            id: 1,
            route: "loans"
        }, {
            name: "Անձնական տվյալներ",
            class: "info",
            id: 2,
            route: "info"
        },
        {
            name: "Հաղորդագրություններ",
            class: "messages",
            id: 3,
            route: "messages"
        },
        {
            name: "Վարկային առաջարկներ",
            class: "suggestions",
            id: 4,
            route: "suggestions",
        }, {
            name: "Ելք",
            class: "exit",
            id: 6,
            // route: "/"
        }
    ]
    useEffect(() => {
        document.getElementById('profileSidebar').classList.contains("open") && setCloseIcon(true)
    },[])

    const chnageComponent = (item) => {
        setActive(item);
        document.getElementById("profileSidebar").classList.remove("open")
        document.body.classList.remove("overflow--hidden")
        if (item.class === "exit") {
            setActive(true)
            window.popupSetter({
                open: true,
                body: <ExitConfirmPopup />,
                popupClassContainer: 'popup__container--sm',
                popupClassInfo: "popup__container__info--sm"
            })        }
    }
    return (
        <div className='profile container'>
            <nav className="sidebar hide_sm" id={"profileSidebar"}>
                <ul className='sidebar__list'>
                    {data.map((item) => (
                        <div key={item.id}>
                            <Link to={item?.route}>
                                <li className={`sidebar__list__item ${item.class} ${active.name === item.name && item.class + ' active'} `}
                                    key={item.id} onClick={() => chnageComponent(item)}>
                                    <span></span>
                                    {item.name}
                                </li>
                            </Link>
                        </div>
                    ))}
                </ul>
            </nav>
            <Outlet/>
        </div>
    )
}
