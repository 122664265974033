import React from 'react';
import message from "../assets/img/popups/messageImg.png"
import MainButton from "./MainButton";
import SignInPopup from "./SignInPopup";
import {Auth} from "../libs/Auth";
import Close from "./Close";

function AlreadyRegistered(props) {
    const isGuest = Auth.isGuest()
    const openSignInPopup = () => {
        window.popupSetter({
            open: false, body: <AlreadyRegistered/>,
            popupClassContainer: 'popup__container--sm',
            popupClassInfo: "popup__container__info--sm"
        })
        window.popupSetter({
            open: true, body: <SignInPopup/>,

        })
    }
    const closePopup = () => {
        window.popupSetter({open:false,body:<AlreadyRegistered />,})
    }
    return (
        <div className='smallPopup'>
            <div onClick={() => closePopup()}>
                <Close />
            </div>
            <img src={message} alt="message icon"/>
            {isGuest ?
                <>
                    <h5 className='smallPopup__title'>Այս տվյալներով կա անձնական էջ</h5>
                    <p className='text-light'>Ձեր անձնական էջի մուտքանունն ու գաղտնաբառը նախկինում ուղարկվել է Ձեր էլ․
                        փոստին։</p>
                    <div className='smallPopup__content'>
                        <div onClick={() => openSignInPopup()}><MainButton text="Մուտք"/></div>
                    </div>
                </>
                :
                <>
                    <h5 className='smallPopup__title'>Այս տվյալներով կա համընկնում</h5>
                    <p className='text-light'>Փոփոխությունը չի կատարվի</p>
                </>
            }
        </div>
    );
}

export default AlreadyRegistered;