import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form';
import "../assets/scss/contact.scss"
import cover from "../assets/img/contact/contactCover.png"
import phone from "../assets/img/contact/phone.svg"
import message from "../assets/img/contact/message.svg"
import fb from "../assets/img/contact/fb.svg"
import clock from "../assets/img/contact/clock.svg"
import ContactSuccess from '../components/ContactSuccess';
import Map from '../components/Map';
import { Api } from '../libs/Api';


export default function Contact() {

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm({});


    const onSubmit = async (data) => {
        Api.post('data/send-contact-mail',{
            "name":data.name,
            "phone":data.phone,
            "email":data.email,
            "body":data.textAreaID,
        }).then(function(response){
            window.popupSetter({open:true,body:<ContactSuccess />,
            popupClassContainer:'popup__container--sm', 
            popupClassInfo:"popup__container__info--sm"})
            reset()
        })
        .catch(function(error){
            console.log("error", error)
        })
    }

  return (
    <div className='contact'>
        <div className='contact__map'>
            <Map />
        </div>
        <div className='contact__bottom '>
            <div className='contact__bottom__form'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <h5 className='contactTitle'>Հետադարձ կապ</h5>
                    <span className='contactSubtitle'>Խնդրում ենք լրացնել ստորև նշված դաշտերը։</span>
                    <div className="formInput formInput--lg">
                        <input
                        type="text"
                        className="formInput__input"
                        placeholder="Անուն"
                        name="name"
                        {...register("name", {
                            required: "Պարտադիր է լրացնել",
                        })}
                        />
                        <span>
                        {errors.name && (
                            <span className="inputError" role="alert">
                            {errors.name.message}
                            </span>
                        )}
                        </span>
                    </div>
                    <div className="formInput formInput--lg">
                        <input
                        type="tel"
                        className="formInput__input"
                        placeholder="Հեռախոսահամար"
                        name="phone"
                        {...register("phone", {
                            required: "Պարտադիր է լրացնել",
                        })}
                        />
                        <span>
                            {errors.phone && (
                                <span className="inputError" role="alert">
                                {errors.phone.message}
                                </span>
                            )}
                        </span>
                    </div>
                    <div className="formInput formInput--lg">
                        <input
                        type="email"
                        className="formInput__input"
                        placeholder="Էլ. փոստ"
                        name="email"
                        {...register("email", {
                            required: "Պարտադիր է լրացնել",
                        })}
                        />
                        <span>
                            {errors.email && (
                                <span className="inputError" role="alert">
                                {errors.email.message}
                        </span>
                            )}
                        </span>
                    </div>
                    <textarea className='contactMessage' name="message" rows={4} type="text"
                        placeholder="Հաղորդագրություն" {...register("textAreaID")}
                    />
                    <input type="submit" className='main-button main-button--blue main-button--fullwidth' value={"Ուղարկել"} />
                    <div className='infoItem infoItem--mob'>
                        <img src={clock} alt="fb icon" />
                        <span>11:00-19:00 երկուշաբթի-ուրբաթ <br /> 11:00-16:00 շաբաթ</span>
                    </div>
                </form>
            </div>
            <div className='contact__bottom__info'>
                <img src={cover} alt="cover"  />
                <div className='infoItem'>
                        <img src={phone} alt="phone icon" />
                        <a href="tel:+37460465555">+374 60 46 55 55</a>
                </div>
                <div className='infoItem'>
                        <img src={message} alt="message icon" />
                        <a href="mailto:credo@credofinance.am">credo@credofinance.am</a>
                </div>
                <div className='infoItem'>
                        <img src={fb} alt="fb icon" />
                        <a target='_blank' rel="noreferrer" href="https://www.facebook.com/CredoFinanceUCO"> CREDO Finance</a>
                </div>
                <div className='infoItem'>
                        <img src={clock} alt="fb icon" />
                        <span>11:00-19:00 երկուշաբթի-ուրբաթ <br /> 11:00-16:00 շաբաթ</span>
                </div>
            </div>
        </div>
    </div>
  )
}
