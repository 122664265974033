import React, {useEffect, useState} from 'react'
import message from "../assets/img/popups/messageImg.png"
import InputMask from 'react-input-mask'
import "../assets/scss/smallPopup.scss"
import {useNavigate} from 'react-router'
import {Api} from '../libs/Api'
import Close from "./Close";


export default function ConfirmSMSPopup(props) {
    const [arrCode, setArrCode] = useState([...Array(4)])
    const [error, setError] = useState()
    const [isDisabled, setIsDisabled] = useState(true)
    const [counter, setCounter] = useState({minutes: 1, seconds: 0});
    const [resend,setResend] = useState(false)
    const [updateTime,setUpdateTime] =  useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        let minutes=1;
        let seconds=0;
        const myInterval = setInterval(() => {
            if (seconds === 0) {
                if (minutes === 0) {
                    setResend(true)
                    clearInterval(myInterval)
                    return;
                } else {
                    minutes--;
                    seconds=60;
                }
            }
            seconds--;
            setCounter({minutes: minutes,seconds: seconds})
        }, 1000);
        return () => clearInterval(myInterval)
    }, [updateTime])

    const writeNumbers = (e, i) => {
        setError("")
        let value = e.target.value
        arrCode[i] = value
        if (e.keyCode === 13 || e.target.value !== " ") {
            // Focus on the next sibling
            e.target.nextElementSibling.focus()
        } else if (e.keyCode === 8 || e.target.value !== " ") {
            // Focus on the previous sibling
            e.target.previousSibling.focus()
        }
    }
    useEffect(() => {
        arrCode.length < 4 || arrCode.includes(" ") ? setIsDisabled(true) : setIsDisabled(false)
    }, [arrCode])

    const resendCode = () => {
        setUpdateTime(!updateTime)
        Api.post('profile/resend-code', {
            "phone": props.phone,
        }).then(function (response) {
            setError("")
        })
            .catch(function (error) {
                console.log("error", error)
            })
    }
    const closePopup = () => {
        window.popupSetter({open: false, body: <ConfirmSMSPopup/>,})
    }
    const onSubmit = () => {
        setTimeout(() => setIsDisabled(false), 10000);
        Api.post('profile/send-code', {
            "phone": props.phone,
            "code": arrCode.join("")
        }).then(function (response) {
            if (response.hasOwnProperty('formError')) {
                setError(response.formError.code.join(', '));

            } else if (response.hasOwnProperty("answer_time_data")) {
                let data = response.answer_time_data
                window.popupSetter({open: false, body: <ConfirmSMSPopup/>,})
                navigate('request-sent', {
                    state: {
                        "date": data.date,
                        "time": data.time,
                        "isActive": data.is_active
                    }
                })
            }
        })
            .catch(function (error) {
                setError(error)
            })
    }
    return (
        <div className='smallPopup'>
            <div onClick={() => closePopup()}>
                <Close/>
            </div>
            <img src={message} alt="message icon"/>
            <p className='text-light'>Խնդրում ենք մուտքագրել <span
                className='text-light--reg'> SMS</span> հաղորդագրությամբ <br/>
                <span className='text-light--reg'>{props.phone}</span> հեռախոսահամարին ուղարկված կոդը</p>
            <span> {counter.minutes} : {counter.seconds < 10 ? `0${counter.seconds}` : counter.seconds}</span>
            <div className='smallPopup__content'>
                <div className='smallPopup__content__span'>
                    {
                        [...Array(4)].map((item, index) => {
                            return <InputMask mask="9" maskChar=" " onKeyUp={(e) => writeNumbers(e, index)}/>
                        })
                    }
                </div>
            </div>
            <div className='smallPopup__bottom'>
                <span>Չե՞ք ստացել կոդը, <a href='#' onClick={() => resendCode()}>նորից ուղղարկել</a> </span>
                {error && <span className="inputError" role="alert">{error}</span>}
                <input onClick={() => onSubmit()} type="submit" value="Հաստատել" disabled={isDisabled}/>
            </div>
        </div>
    )
}
