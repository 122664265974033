import React from 'react';
import {useLocation, useNavigate} from "react-router-dom";

function ProfileLoanDetails() {
    const location = useLocation();
    const loanDetails = location.state.loanInfo
    const navigate = useNavigate()
    const goBack = () => {
        navigate(-1)
    }

    return (
        <div className="loan-details">
            <h4 className='text-blue text-blue--lg'>Վարկի մանրամասներ</h4>
            <table className='profileLoans__table'>
                <tbody>
                <tr className="profileLoans__rowMb">
                    <td className="name">Գումար</td>
                    <td>{loanDetails.loan_size}</td>
                </tr>
                <tr className="profileLoans__rowMb">
                    <td className="name">Ժամկետ</td>
                    <td>{loanDetails.loan_day_count}</td>
                </tr>
                <tr className="profileLoans__rowMb">
                    <td className="name">Դիմումի օր/ժամ</td>
                    <td>{loanDetails.create_date}</td>
                </tr>
                <tr className="profileLoans__rowMb">
                    <td className="name">Տրվել է</td>
                    <td>{loanDetails.loastart_date ? loanDetails.loastart_date : "-"}</td>
                </tr>
                <tr className="profileLoans__rowMb">
                    <td className="name">Մարման օր</td>
                    <td>{loanDetails.end_date ? loanDetails.end_date : "-"}</td>
                </tr>
                <tr className="profileLoans__rowMb">
                    <td className="name">Կարգավիճակ</td>
                    <td>
                        <div
                            className={`loanStatus loanStatus--${loanDetails.status}`}>{loanDetails.status_title}</div>
                    </td>

                </tr>
                <tr className="profileLoans__rowMb">
                    <td className="name">Պայմանագիր</td>
                    <td>{loanDetails.agreement_number}</td>
                </tr>
                </tbody>
            </table>
            <div className="show_sm profileLoans__bottom" onClick={() => goBack()} >
                <div className='main-button main-button--blue '>Վերադառնալ</div>
            </div>
        </div>
    );
}

export default ProfileLoanDetails;