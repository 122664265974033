import React, { useEffect, useState } from 'react'
import "../assets/scss/fourthStep.scss"
import {userFields} from "../libs/datas";
import LoanFormService from "../services/loanFormService";

export default function FourthStep(props) {
    const [mobileView,setMobileView] = useState(false)

    let pageWidth = window.innerWidth
    const currentStep=props.currentStep;
    const formFields=userFields;
    let data=LoanFormService.getFormData('userInfo');
    useEffect(() => {
        if (pageWidth<750) setMobileView(true)
       },[pageWidth])
    const drawField = (field, index) => {
        let value;
        let type = field.type || 'text';
        switch (type) {
            case 'list':
                value = data[field.key+'_label'];
                break;
            default:
                value = data[field.key];
                break;
        }
        return <div className='fourthStep__item' key={index+field.key}>
            <span className='fourthStep__item__label'>{field.label}</span>
            <span className='fourthStep__item__value'>{value}</span>
        </div>
    }
    if(currentStep===4){
        return (
            <>
            <div className='fourthStep'>
                <div className='fourthStep__content'>
                    <h5 className='fourthStep__title'>Խնդրում ենք ստուգել մուտքագրված տվյալների ճշտությունը։</h5>
                    <span className='fourthStep__subtitle'>Անձնական տվյալներ</span>
                    <div className='personalInfo__fields'>
                        {formFields.userFields.map((item)=>{
                            return drawField(item);
                        })}
                    </div>
                    <span className='fourthStep__subtitle'>Անձը հաստատող տվյալներ</span>
                    <div className='personalInfo__fields'>
                        {formFields.passportFields.map((item)=>{
                            return drawField(item);
                        })}
                    </div>
                </div>
                <div className='popup-bottom'>
                <span className='text-blue-sm text-blue-sm--pointer text-blue-sm--gray' onClick={() => props.closePopup()}>Չեղարկել</span>
                <div>
                    {mobileView ? 
                        <button type={"button"} className='main-button--mobBlue mobileBtnWhiteBlue' onClick={() => {props.setCurrentStep(3)}} /> :   
                        <button type={"button"} className='main-button main-button--xl-md popup-bottom__buttonBack text-blue-sm text-blue-sm--pointer'
                        onClick={() => {props.setCurrentStep(3)}}>Նախորդ</button>}   
                    {mobileView ? <button onClick={() => props.setCurrentStep(5)} className='main-button--mobile mobileBtnGradient' /> :<button onClick={() => props.setCurrentStep(5)} className='main-button main-button--xl-md main-button--gradient popup-bottom__button'>Շարունակել</button>}
                </div>
            </div>
            </div>
            </>
        )
    }

}
