import React, { useEffect, useState } from 'react'
import TermNavItem from '../components/TermNavItem'
import "../assets/scss/terms.scss"
import { Api } from '../libs/Api';

export default function Terms() {
    const [text,setText] = useState()
    const [active,setActive] = useState(1)


    useEffect(() => {
        Api.get(`data/get-page-content`,{id:321}).then((data) => {
          setText(data.html)
        })
      },[]);

    const data=[
        {
            name:"Օգտագործման պայմաններ",
            pdf:"",
            id :1,
        },
        {
            name:"Կայքի օգտագործումը",
            pdf:"",
            id:2,
        },
        {
            name:"Պատասխանատվության բացառումը",
            pdf:"",
            id:3,
        },
        {
            name:"Կիրառելի իրավունք և վեճերի լուծում",
            pdf:"",
            id:4,
        }
    ]
    const scrollToSection = (id) => {
        const section = document.getElementById(``+id);
        section.scrollIntoView({ behavior: 'smooth',block: "center" });
        setActive(id)
    }


  return (
    <div className='terms container'>
        <nav className='terms__list'>
          {
            data.map((navitem) =>{
              return <ul key={navitem.id}>
                <li className={`${ active===navitem.id ? "termsActive" : ` `} text-gray-xs text-gray-xs--md`} onClick={() => {scrollToSection(navitem.id)}} >{navitem.name}</li>
              </ul>
            })
          }
        </nav>
        <div className='terms__content'>
            <div className='terms__content__top'>
                <h5 className='title'>Օգտագործման պայմաններ</h5>
                <span className='text-gray-xs'>Նորացման ամսաթիվ 20 օգոստոսի, 2016թ. <br/>Ընդհանուր դրույթներ</span>
            </div>
        <div className='print'>
          </div>            <div  dangerouslySetInnerHTML={{__html: text}}>
        </div>
        </div>
    </div>
  )
}
