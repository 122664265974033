import moment from "moment";

export function checkLogin() {
    return localStorage.get('token') && true

}

export function numberFormat(number, decimals, dec_point, thousands_sep) {
    number = (number + '')
        .replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + (Math.round(n * k) / k)
                .toFixed(prec);
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n))
        .split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '')
        .length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1)
            .join('0');
    }
    return s.join(dec);
}

export var selectStyle = {
    control: (baseStyles) => ({
        ...baseStyles,
        border: '1.5px solid #A9B5BC',
        borderRadius: '8px',
        paddingTop: '3px',
        paddingBottom: '3px',
        color: '#333333',
        maxWidth: '290px',
        textTransform: 'capitalize'
    }),
    option: (provided) => ({
        ...provided,
        color: '#333333',
        backgroundColor: 'white',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: 'rgba(169, 181, 188, .2)'
        }
    }),
    singleValue: provided => ({
        ...provided,
        color: '#333333',
    })
}