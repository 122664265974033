import React from 'react'
import "../assets/scss/signInPopup.scss"
import SignInForm from './SignInForm'

export default function SignInPopup() {

  return (
 
        <div className='signInPopup'>
            <div className='signInPopup__left'>
                <h3 className='text-blue'>Ինչի՞ համար է անձնական էջը</h3>
                <p className='text-light'>Հարգելի’ Հաճախորդ,
                <br />
                    վարկային դիմում լրացնելիս, Ձեզ համար բացվում է անձնական էջ, որի մուտքանունը և գաղտնաբառը ուղարկվում է Ձեր էլեկտրոնային փոստին:
                <br />
                Անձնական էջը թույլ է տալիս կատարել անձնական տվյալների թարմացում, ստանալ գործող և մարված վարկերի վերաբերյալ տեղեկատվություն, լրացնել վարկային դիմում և այլն:</p>
            </div>
            <div className='signInPopup__right'>
                <span className='signInPopup__right__title'>Մուտք գործել</span>
                <SignInForm />
            </div>
        </div>
  )
}
