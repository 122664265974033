import React, {useState} from 'react'
import FirstStep from './FirstStep';
import StepNavigation from './StepNavigation'
import ThirdStep from './ThirdStep';
import "../assets/scss/stepPopup.scss"
import FifthStep from './FifthStep';
import FourthStep from "./FourthStep";
import PersonalInfo from "./PersonalInfo";

export default function PopupSteps(props) {
    const [currentStep, setCurrentStep] = useState(1);
    const scrollRight = () => {
        let element=document.querySelector(".stepPopup__top")
        element.scrollLeft=element.scrollWidth
    }
    currentStep>3 && scrollRight()

    return (
        <div>
            {props.open &&
                <div className={`popup active `}>
                    <div className="popup__background" onClick={() => props.closePopup()}></div>
                    <div className="popup__container popup__container--full">
                        <div className="popup__container__info popup__container__info--full">
                            <div className='stepPopup'>
                                    <div className='stepPopup__top'>
                                        <StepNavigation currentStep={currentStep}/>
                                    </div>
                                    <div className={'stepContent'}>
                                        <div className={'stepPage '+(currentStep === 1 ? '' : 'hidden')}>
                                            <FirstStep closePopup={props.closePopup} setCurrentStep={setCurrentStep}/>
                                        </div>
                                        <div className={'stepPage '+(currentStep === 2 ? '' : 'hidden')}>
                                            <PersonalInfo isStepForm={1} closePopup={props.closePopup} setCurrentStep={setCurrentStep}/>
                                        </div>
                                        <div className={'stepPage '+(currentStep === 3 ? '' : 'hidden')}>
                                            <ThirdStep closePopup={props.closePopup} setCurrentStep={setCurrentStep}/>
                                        </div>
                                        <div className={'stepPage '+(currentStep === 4 ? '' : 'hidden')}>
                                            <FourthStep currentStep={currentStep} closePopup={props.closePopup} setCurrentStep={setCurrentStep}/>
                                        </div>
                                        <div className={'stepPage '+(currentStep === 5 ? '' : 'hidden')}>
                                            <FifthStep currentStep={currentStep} closePopup={props.closePopup} setCurrentStep={setCurrentStep}/>
                                        </div>
                                    </div>

                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}
