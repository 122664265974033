import React from 'react';
import Close from "./Close";

function LoanExistsPopup(props) {
    const closePopup = () => {
        window.popupSetter({open: false, body: <LoanExistsPopup/>,})
    }
    return (
        <div className='smallPopup'>
            <div onClick={() => closePopup()}>
                <Close/>
            </div>
            <h5 className='smallPopup__lgtext' > Դուք արդեն ունեք դիտարկման փուլում գտնվող վարկ, խնդրում ենք սպասել մինչև  <br />
                <span className={"smallPopup__lgtext--blue"}>{props.endDate && props.endDate.date} {props.endDate && props.endDate.time}</span>
            </h5>
            <p className={"smallPopup__xstext"}> Հարցերի դեպքում՝ 060465555 </p>

        </div>
)
}

export default LoanExistsPopup;
