import React, {useEffect, useState} from 'react'
import Close from './Close'
import warning from "../assets/img/popups/warning.svg"
import {Link} from 'react-router-dom'
import PopupSteps from './PopupSteps'
import arrow from "../assets/img/popups/downArrow.svg"
import CancelReasonPopup from './CancelReasonPopup'
import checkExistingLoan from "../services/checkExistingLoan";
import {Auth} from "../libs/Auth";

export default function PopupLarge(props) {
    const [openPopup, setOpenPopup] = useState(false)
    const [mobileView, setMobileView] = useState(false)
    const [isLoggedIn, setIsLoggedIn] = useState()

    let pageWidth = window.innerWidth

    const isGuest = !Auth.isGuest()

    useEffect(() => {
        setIsLoggedIn(isGuest)
    }, [isGuest])

    const  openPopupSteps = async () => {
        if (isLoggedIn && await checkExistingLoan()) {
            setOpenPopup(false);
        } else {
            setOpenPopup(true)
            document.body.style.overflow = 'hidden';
            props.closePopup()
        }
    }

    const closePopup = () => {
        setOpenPopup(false)
        document.body.style.overflow = 'unset';
        window.popupSetter({open: true, body: <CancelReasonPopup/>})
        props.closePopup()

    }

    const scrollDown = () => {
        document.querySelector(".popup").scrollTo(0, document.querySelector(".popup__container").scrollHeight - 500, "smooth");
    }

    return (
        <>
            <PopupSteps closePopup={closePopup} open={openPopup}/>
            {props.open &&
                <div className={` popup  ${props.open ? "active" : ""}`}>
                    <button className="popup__downArrow" onClick={() => scrollDown()}>
                        <img src={arrow} alt="arrow"/>
                    </button>
                    <div className="popup__background" onClick={() => props.closePopup()}></div>
                    <div className="popup__container popup__container--lg">
                        <div className="closeLayer" onClick={() => props.closePopup()}></div>
                        <div className=" popup__container__info">
                            <div onClick={() => props.closePopup()}>
                                <Close/>
                            </div>
                            <div dangerouslySetInnerHTML={{__html: props.data.html}}></div>
                            <div className='popupBottom'>
                                <div className='popupBottom__content'>
                                    <div className='popupBottom__content__text'>
                                        <img src={warning} alt="warning icon"/>
                                        <span>Ուշադրություն, պարտադիր ծանոթացե՛ք վարկավորման պայմանների հետ որպեսզի կարողանաք շարունակել գործարքը: <a
                                            target="_blank"
                                            href="https://www.credofinance.am/wp-content/uploads/2023/09/varkavorman-payhmanner.25.09.pdf">Բեռնել մանրամասն պայմանները</a></span>
                                    </div>
                                    <div className='popupBottom__content__buttons'>
                                        <div onClick={() => closePopup()}
                                             className='main-button main-button--withBorder main-button--lg'>Չեղարկել
                                        </div>
                                        <div onClick={() => openPopupSteps()}
                                             className='main-button main-button--blue main-button--xxl'>Դիմել
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
        </>
    )
}
