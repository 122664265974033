import React from 'react'
import "../assets/scss/advantagesItem.scss"

export default function AdvantagesItem(props) {
  return (
    <div className='col-4 advantagesItem'>
        <div className='advantagesItem__img'>
            <img src={props.image} alt={props.title}/>
        </div>
        <h4 className='text-blue'>{props?.title}</h4>
        <p className='text-gray-sm'>{props.text}</p>
    </div>
  )
}
