import {Route, Routes} from 'react-router-dom';
import RoutesItems from "./routes";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import './App.scss'
import Popup from './components/Popup';
import {useEffect, useReducer} from 'react';
import { globalReducer, initialState } from './Globals';
import GuardedRoute from "./components/GuardedRoute"
// import RouteChangeTracker from "./routeChangeTracker";
// import ReactGA from 'react-ga';
import ReactGA from "react-ga4";





function App() {
    const [state, dispatch] = useReducer(globalReducer, initialState);

    //for google analytics
    ReactGA.send({ hitType: "pageview", page: "/", title: "Test Page" });

    ReactGA.initialize("G-5KRJBFBMWJ");

    // ReactGA.send({ hitType: "pageview", page: "/my-path", title: "Custom Title" });


    // const TRACKING_ID = "G-5KRJBFBMWJ";
    // ReactGA.initialize(TRACKING_ID);
    //
    // useEffect(() => {
    //     ReactGA.pageview(window.location.pathname + window.location.search);
    // }, []);
    function setPopupData(popupData) {
        let data = {
            type: 'setPopupData',
            popupData: popupData
        }
        dispatch(data);
    }
    const getRoutes=(data)=>{
        return data.map((routeConfig, i) => {
            let subRoutes=[];
            if(routeConfig.items){
                subRoutes = routeConfig.items.map((subRoute,j)=>{
                    return <Route path={subRoute.path} exact={subRoute.exact} element={subRoute.element} key={i+'_'+j} />
                })
            }
            return (
                <Route path={routeConfig.path} exact={routeConfig.exact} element={routeConfig.element} key={i}>{subRoutes}</Route>
            )
        })
    }
    return (
        <div className="App">
            {/*<RouteChangeTracker />*/}
            <div>
                    <Header/>
                    <Routes>
                        {getRoutes(RoutesItems.simpleRoutes)}
                        <Route exact path='/' element={<GuardedRoute isGuest={false}/>}>
                            {getRoutes(RoutesItems.loggedInRoutes)}
                        </Route>
                    </Routes>
                </div>
                <Footer/>
                <Popup setter={setPopupData} popupData={state.popupData}/>
        </div>
    );
}

export default App;
