import React from 'react'
import pin from "../assets/img/contact/pin.svg"
import "../assets/scss/mapSection.scss"

export default function MapPin(props) {
  return (
    <div>
        <img src={pin} alt="pin icon" width={"28px"} height={"38px"} />
        <span className={"mapPinText"}>{props.text}</span>
    </div>
  )
}
