import React, { useEffect, useState } from 'react'
import "../assets/scss/requestSent.scss"
import message from "../assets/img/contact/messagePopup.png"
import MainButton from '../components/MainButton'
import SignInPopup from '../components/SignInPopup'
import { Auth } from '../libs/Auth'
import announcement from "../assets/img/formSteps/announcement.svg"
import { useLocation } from 'react-router-dom'



export default function RequestSent() {
    const [isLoggedIn, setIsLoggedIn] = useState()
    const location = useLocation();

    const isGuest= !Auth.isGuest()
    useEffect(() =>{
      setIsLoggedIn(isGuest)
    },[isGuest])

    useEffect(() => {
        window.scrollTo({top:0})
        document.body.style.overflow = 'auto'
    },[])


    const openSignInPopup = () =>{
        window.popupSetter({open:true,body:<SignInPopup />})
      }  
  return (
    <div className='requestSent container'>
        <div className='requestSent__content'>
            <img src={message} alt="message sent img"/>
            <h5 className='requestSent__content__title'>Շնորհակալություն</h5>
            <p className='requestSent__content__text'>Ձեր դիմումն ընդունված է։ <br />Մեր մասնագետը կտեղեկացնի Ձեզ հետագա քայլերի մասին
                <span className='requestSent__content__text--blue'>
                &nbsp; մինչև {location.state.date}թ., ժամը {location.state.time}-ը։
                </span> 
            </p>
            {!location.state.isActive &&
            <div className='show_sm requestSent__textBox '>
                <img src={announcement} alt="announcement icon" />
                <span>Այժմ ոչ աշխատանքային ժամ է, ուստի վարկի դիմումի պատասխանը կստանաք հաջորդ աշխատանքային օրվա ընթացքում:</span>
            </div>
            }
            {!isLoggedIn && <p className='requestSent__content__text'>Մուտք գործեք Ձեր անձնական էջ, որպեսզի ծանոթանաք դիմումի ընթացքին։</p>}
            {!isLoggedIn && <p className='requestSent__content__text'>Անձնական էջի մուտքանունն ու գաղտնաբառը ուղարկվել է Ձեր էլ․ փոստին։</p>}
            <div className='requestSent__content__buttons'>
            {!isLoggedIn && <div onClick={() => openSignInPopup()}><MainButton text="Մուտք գործել"/></div>}
                <a href="/" className='text-blue-sm'>Գլխավոր էջ</a>
            </div>
        </div>
        {!location.state.isActive &&
            <div className='hide_sm requestSent__textBox requestSent__textBox--absolute'>
                <img src={announcement} alt="announcement icon" />
                <span>Այժմ ոչ աշխատանքային ժամ է, ուստի վարկի դիմումի պատասխանը կստանաք հաջորդ աշխատանքային օրվա ընթացքում:</span>
            </div>
        }
    </div>
  )
}
