import React from 'react'
import message from "../assets/img/contact/messagePopup.png"
import Close from "./Close";

export default function ContactSuccess(props) {
    const closePopup = () => {
        window.popupSetter({open:false,body:<ContactSuccess />,})
    }
  return (
    <div className='smallPopup'>
        <div onClick={() => closePopup()}>
            <Close />
        </div>
      <img src={message} alt="message icon"/>
      <h5 className='smallPopup__title'>Շնորհակալություն</h5>
      <p className='text-light'>{props?.text ? props?.text: "Ձեր հաղորդագրությունը հաջողությամբ ուղարկվել է։"}</p>
      <div className='smallPopup__content'>
      </div>
    </div>
  )
}
