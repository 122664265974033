import React, {useEffect, useState, useRef} from 'react'
import message from "../assets/img/popups/messageImg.png"
import "../assets/scss/smallPopup.scss"
import Close from "./Close";
import {Auth} from "../libs/Auth";


export default function ExitConfirmPopup(prps) {

    const closePopup = () => {
        window.popupSetter({open: false, body: <ExitConfirmPopup />,})
    }
    const logout = () =>{
        window.popupSetter({open: false, body: <ExitConfirmPopup />,})
        Auth.logout()
    }
    return (
        <div className='smallPopup'>
            <div onClick={() => closePopup()}>
                <Close/>
            </div>
            <img src={message} alt="message icon"/>
            <p className='text-light'>Համոզվա՞ծ եք, որ ցանկանում եք դուրս գալ Ձեր անձնական էջից:</p>
            <div className='smallPopup__buttons'>
                <input className={"main-button main-button--blue"} onClick={() => logout()} type="submit" value="Այո" />
                <input className={"main-button main-button--withBorder"} onClick={() => closePopup()} type="submit" value="Ոչ"/>
            </div>
        </div>
    )
}
