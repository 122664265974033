import React from 'react'
import "../assets/scss/footer.scss"
import email from "../assets/img/footer/email.svg";
import phone from "../assets/img/footer/phone.svg";
import website from "../assets/img/footer/website.svg";
import fb from "../assets/img/footer/fb.svg";
import hashtarar from "../assets/img/footer/hashtarar.svg";
import fin2 from "../assets/img/footer/fin2.svg";
import cb from "../assets/img/footer/cb.svg";
import fininfo from "../assets/img/footer/fininfo.svg";
import tiktok from "../assets/img/footer/tiktok.svg";

export default function Footer() {
    let current_date = new Date();
    const contacts=[
        {
            icon:phone,
            text:'+374 60 46 55 55',
            link:"tel:+37460465555"
        },
        {
            icon:email,
            text:'credo@credofinance.am',
            link:"mailto:credo@credofinance.am"
        },
        {
            icon:website,
            text:'credofinance.am',
            link:"https://www.credofinance.am/"
        },
        {
            icon:fb,
            text:'@CredoFinanceUCO',
            link:"https://www.facebook.com/CredoFinanceUCO"
        },
        {
            icon:tiktok,
            text:'Credo_finance',
            link:"https://www.tiktok.com/@credo_finance"
        }
    ]
    const links=[
        {
            icon:hashtarar,
            text:'Ֆինանսական Համակարգի հաշտարար',
            link:"https://fsm.am/"
        },
        {
            icon:fin2,
            text:'Ֆինանսներ բոլորի համար',
            link:"https://abcfinance.am/"
        },
        {
            icon:cb,
            text:'Կենտրոնական բանկ',
            link:"https://www.cba.am/"
        },
        {
            icon:fininfo,
            text:'Ձեր ֆինանսական տեղեկատուն',
            link:"http://www.fininfo.am/"
        },
    ]

    return (
        <footer className={"footer"}>
            <div className={"container"}>
                <div className={"row"}>
                    <div className={"col6"}>
                        <div className={"footer__left_title footer__title"}>Կազմակերպությունը վերահսկվում է ՀՀ Կենտրոնական բանկի կողմից</div>
                        <div className={"footer__left_text"}>
                            Ընկերությունը պատասխանատվություն չի կրում իր կայքում հղում կատարված ինտերնետային կայքերի բովանդակության ստույգության և արժանահավատության, այնտեղ տեղադրված գովազդների և տեղեկատվության օգտագործման հնարավոր հետևանքների համար:
                        </div>
                        <div className={"footer__divider footer__left_divider"}></div>
                        <div className={"footer__left_text"}>
                            Կրեդո Ֆինանս ՈՒՎԿ ՓԲԸ կողմից մատուցվող ծառայությունների վերաբերյալ ամբողջական տեղեկատվություն ստանալու համար այցելեք մեր ընկերության պաշտոնական ինտերնետային կայք՝                            <a
                            href="https://www.credofinance.am/">www.credofinance.am</a>
                        </div>
                        <div className={"footer__divider footer__left_divider"}></div>

                        <p>Բոլոր իրավունքները պաշտպանված են:</p>
                        <p>Powered by NATK Systems</p>
                        <p>&#169; "ԿՐԵԴՈ ՖԻՆԱՆՍ" ՈՒՎԿ ՓԲԸ</p>
                        <p>2013-{current_date.getFullYear()}</p>
                    </div>
                    <div className={"col3"}>
                        <div className={"footer__title footer__right_title"}>Կոնտակտներ</div>
                        {
                            contacts.map((item,i)=>{
                                return <a target="_blank" rel="noreferrer" href={item?.link} key={i} className={"footer__info_row"}><span className={"footer__constants_icons"}><img src={item.icon} alt={item.text} /></span> {item.text}</a>
                            })
                        }
                        <div className='footer__link'><a href="/terms">Օգտագործման պայմաններ</a></div>
                    </div>
                    <div className={"col3"}>
                        <div className={"footer__title footer__right_title"}>Օգտակար հղումներ</div>
                        {
                            links.map((item,i)=>{
                                return <a key={i} target="_blank" rel="noreferrer" href={item?.link} className={"footer__info_row"}><span className={"footer__links_icon"}><img src={item.icon} alt={item.text}/></span> {item.text}</a>
                            })
                        }
                    </div>
                </div>
            </div>
        </footer>
    );

}
