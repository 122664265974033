import React from 'react'
import "../assets/scss/advantages.scss"
import rocket from "../assets/img/rocket.svg"
import calendar from "../assets/img/calendar.svg"
import pigImg from "../assets/img/pig.svg"
import AdvantagesItem from './AdvantagesItem'


export default function Advantages() {
    const data=[
        {
            url:rocket,
            title:"Ստացիր արագ վարկեր",
            text:"Օնլայն վարկի կայծակնային հաստատում",
        },
        {
            url:calendar,
            title:"Մարիր առանց ուշացման",
            text:"Մարիր 4 ամիս առանց ուշացման, ստացիր 1% իջեցում"
        },
        {
            url:pigImg,
            title:"Հրավիրիր ընկերոջըդ և ",
            text:"Վարկի հաստատման դեպքում ստացիր -1% քո վարկից"
        }
    ]
  return (
    <div className='advantages'>
        <h3 className='advantages__title'>Մեր առավելությունները</h3>
        <div className='advantages__content'>
            {data.map((item,i) => (
                <AdvantagesItem key={i} image={item.url} title={item.title} text={item.text} />
            ))}
        </div>
    </div>
  )
}
