import React, {useState, useEffect} from 'react'
import "../assets/scss/homeCalc.scss"
import time from "../assets/img/calculator/time.svg"
import amd from "../assets/img/calculator/amdRed.svg"
import {Api} from '../libs/Api'
import PopupLarge from './PopupLarge'
import {numberFormat} from "../libs/globalFunctions";
import LoanFormService from '../services/loanFormService'
import ReactSimpleRange from "react-simple-range";
import ReactGA from 'react-ga4';


export default function HomeCalculator() {
    const [loanInfo, setLoanInfo] = useState({id: 4})
    const [openPopup, setOpenPopup] = useState(false)
    const [popupData, setPopupData] = useState(null)
    const [loanConfigs, setLoanConfigs] = useState();
    const [sum, setSum] = useState(100000)
    const [months, setMonths] = useState(6)


    useEffect(() => {
        Api.get('data/get-loan-config').then((data) => {
            setLoanConfigs(data);
            setLoanInfo(data[4]);
        })
    }, [])


    const handleSumChange = (e) => setSum(e.value);
    const handleMonthChange = (e) => setMonths(e.value);

    const orderType = [
        {id: 4, label: "Առանց գրավի"},
        {id: 6, label: "Գրավով"},
        {id:7, label:"Ապառիկ"}
    ]
    const toggle = (i) => {
        let new_loan_data = loanConfigs[i];

        if (sum < new_loan_data.min) {
            setSum(new_loan_data.min)
        }
        if (sum > new_loan_data.max) {
            setSum(new_loan_data.max)
        }
        if (months < new_loan_data.min_days) {
            setMonths(new_loan_data.min_days);
        }
        if (months > new_loan_data.max_days) {
            setMonths(new_loan_data.max_days);
        }
        setLoanInfo(loanConfigs[i]);
    }
    const openPopupLarge = () => {
        setOpenPopup(true)
        document.body.classList.add("overflow--hidden")
    }

    const closePopup = () => {
        setOpenPopup(false)
        document.body.classList.remove("overflow--hidden")
    }

    const handleSubmit = () => {
        ReactGA.event("dimel_hima");
        Api.get(`data/get-loan-conditions`, {loan_id: loanInfo.id}).then((data) => {
            openPopupLarge()
            setPopupData(data)
            LoanFormService.setFormData('loanInfo', {
                loan_id: loanInfo.id,
                months: months,
                sum: sum
            })
        })
    }


    return (
        <>
            <PopupLarge closePopup={closePopup} open={openPopup} data={popupData}/>
            <div className='homeCalc'>
                <ul className='homeCalc__item '>
                    {
                        orderType.map((item, i) => {
                            return <li key={item.id} onClick={() => toggle(item.id)}
                                       className={loanInfo.id === item.id ? 'homeCalc__item active' : ""}>{item.label}</li>
                        })
                    }
                </ul>
                <div className='homeCalc__content'>
                    <div className='homeCalc__content__box'>
                        <div className={"insideInput"}>
                            <img src={time} alt="time icon"/>
                            <span>{months}</span>
                        </div>
                        <ReactSimpleRange
                            min={loanInfo.min_days}
                            max={loanInfo.max_days}
                            defaultValue={months}
                            sliderSize={2}
                            eventWrapperPadding={0}
                            trackColor={"#2B3B57"}
                            sliderSize={2}
                            sliderColor={"#EAEBEE"}
                            thumbColor={"#EAEBEE"}
                            onChange={handleMonthChange}
                            customThumb={
                                <div className={"blob"}
                                ></div>
                            }
                        />
                    </div>
                    <div className='homeCalc__content__box'>
                        <div className={"insideInput"}>
                            <img src={amd} alt="time icon"/>
                            <span>{numberFormat(sum)}</span>
                        </div>
                        <ReactSimpleRange
                            min={loanInfo.min}
                            max={loanInfo.max}
                            eventWrapperPadding={0}
                            // defaultValue={sum}
                            step={10000}
                            trackColor={"#2B3B57"}
                            sliderSize={2}
                            sliderColor={"#EAEBEE"}
                            thumbColor={"#EAEBEE"}
                            onChange={handleSumChange}
                            customThumb={
                                <div className={"blob"}
                                ></div>
                            }
                        />
                    </div>
                    <div>
                        <input
                            className="main-button main-button--gradient main-button--xl"
                            type="submit"
                            value="Դիմել հիմա"
                            onClick={() => handleSubmit()}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
