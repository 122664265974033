import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import "../assets/scss/signInForm.scss"
import ForgotPassword from './ForgotPassword';
import { Api } from '../libs/Api';
import { Auth } from '../libs/Auth';
import SignInPopup from './SignInPopup';
import {useNavigate} from "react-router-dom"



export default  function SignInForm() {
  const [showPassword,setShowPassword] = useState(false)
  const navigate = useNavigate();
    
    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
        reset,
      } = useForm({});

      const onSubmit = async (data) => {
        const username=data.username;
        const password=data.password;
        Api.post('auth/login',{
          "username":username,
          "password":password
        }).then(function(response){
          if(response.formError){
            setError('root.validationPassError', { 
              type: response.formError?.password[0],
            })
            setError('root.validationUsernameError', { 
              type: response.formError?.username[0],
            })
          }else{
            Auth.login(response)
            window.popupSetter({open:false,body:<SignInPopup />})
          }
          navigate("/profile/loans")
        })
        .catch(function(error){
          console.log("error", error)
        })
      }


      const openForgotPasswordPopup= () =>{
        window.popupSetter({open:true,body:<ForgotPassword />})
      }  
        
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="signInForm">
      <div className="formInput">
                  <label htmlFor="message" className="formInput__label">
                  Մուտքանուն
                  </label>
                <input
                  type="text"
                  className="formInput__input"
                  placeholder="374"
                  {...register("username", {
                    required: "Պարտադիր է լրացնել",

                    pattern: {
                      message: "Մուտքանունը սխալ է",

                    },
                  })}
                />
                <span>
                  {errors.email && (
                    <span className="inputError" role="alert">
                      {errors.email.message}
                    </span>
                  )}
                </span>
                <span>{errors.root?.validationUsernameError?.type && <span className="inputError" role="alert">
                    {errors.root?.validationUsernameError?.type}
                </span>}</span>

      </div>
      <div className="formInput">
                <label htmlFor="message" className="formInput__label">
                Գաղտնաբառ
                </label>
                <input
                  className={`formInput__input`}
                  type={showPassword ? "text" : "password"}
                  {...register("password", {
                    required: "Պարտադիր է լրացնել",
                  })}
                  
                />
                <span className={` ${!showPassword ? 'passwordIcon' : 'passwordIcon show' }`} onClick={() => setShowPassword(!showPassword)}></span>
                
                <span>
                {errors.password && (
                  <span className="inputError" role="alert">
                    {errors.password.message}
                  </span>
                )}
                </span>
                <span>
                {errors.root?.validationPassError?.type && <span className="inputError" role="alert">
                    {errors.root?.validationPassError?.type}
                </span>}
                </span>
      </div>
      <div className="signInForm__forgotPass" onClick={() => openForgotPasswordPopup()}>
        Մոռացե՞լ եք գաղտնաբառը
      </div>
      <div className="signInForm__submit">
                <input
                  className="main-button main-button--blue"
                  type="submit"
                  value="Մուտք գործել"
                />
      </div>
    </form>
  )
}
