import React from 'react'

export default function Messages() {
  return (
      <div className="profileEmpthy">
        <h4 className='text-blue text-blue--lg personalInfo__title'>Հաղորդագրություններ</h4>
      </div>
)
}

