import React from 'react'
import { useForm } from 'react-hook-form';
import { useNavigate, useParams} from 'react-router-dom';
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Api } from '../libs/Api';
import SuccessChangeInfo from "../components/SuccessChangeInfo"

export default function ForgetPasswordPage() {
    const params = useParams()
    const navigate= useNavigate()

    const formSchema = Yup.object().shape({
        newPassword: Yup.string(),
        repeatPassword: Yup.string()
          .required("Պարտադիր է լրացնել")
          .oneOf([Yup.ref("newPassword")], "Գաղտնաբառերը չեն համապատասխանում")
      });

    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm({
        mode: "onTouched",
        resolver: yupResolver(formSchema)
      });

      const onSubmit = async (data) => {
        Api.post('profile/forgot-password-change',{
          "password":data.newPassword,
          "key":params.key
        }).then(function(response){
            window.popupSetter({
                open: true, body: <SuccessChangeInfo />,
                popupClassContainer: 'popup__container--sm',
                popupClassInfo: "popup__container__info--sm"
            })
            navigate("/")
            
        })
        .catch(function(error){
          console.log("error", error)
        })
      }
  return (
    <form className='forgotPass' onSubmit={handleSubmit(onSubmit)}>
        <div>
        <h4 className='text-blue text-blue--lg personalInfo__title'>Փոխել գաղտնաբառը</h4>
        <div className='changePassword'>
        <div className="formInput">
                <label htmlFor="message" className="formInput__label">
                 Նոր գաղտնաբառ *
                </label>
                <input
                  className={`formInput__input`}
                  type="password"
                  {...register("newPassword", {
                  })}
                />
                {errors.newPassword && (
                  <span className="inputError" role="alert">
                    {errors.password.message}
                  </span>
                )}
        </div>
        <div className="formInput">
                <label htmlFor="message" className="formInput__label">
                Կրկնել նոր գաղտնաբառը *
                </label>
                <input
                  className={`formInput__input`}
                  type="password"
                  {...register("repeatPassword", {
                  })}
                />
                {errors.repeatPassword && (
                  <span className="inputError" role="alert">
                    {errors.password?.message}
                  </span>
                )}
        </div>
        <p className="inputAlert">{errors.repeatPassword?.message}</p>
        </div>
        <input
          className="main-button main-button--blue main-button--lg changePassword__submit"
          type="submit"
          value="Պահպանել"
        />
        </div>
    </form>
  )
}
