import React, { useLayoutEffect, useState } from 'react'

export default function FileExamplePopup(props) {
  const [object,setObject]=useState()

  useLayoutEffect(() => {
    if(typeof(props.file)==="object"){
      return setObject(window.URL.createObjectURL(props.file))
    }else if(typeof(props.file)==="string"){
      return setObject(props.file)

    }
  },[props.file])
  
  return (
    <div className='flex-center'>
        <img src={object} alt="file example" />
    </div>
  )
}
