import React, {useEffect, useState} from 'react'
import banner from "../assets/img/banner/banner.png"
import smile from "../assets/img/banner/shape.svg"
import badge from "../assets/img/banner/badge.svg"
import user from "../assets/img/banner/userImg.svg"
import envelope from "../assets/img/banner/envelope-star.svg"
import "../assets/scss/homeBanner.scss"
import "../assets/scss/bannerFrame.scss"
import PopupLarge from './PopupLarge'
import animationData from '../assets/img/line.json'
import yellowLine from "../assets/img/yellow-line.svg"


export default function HomeBanner() {
    const [lineWidth, setLineWidth] = useState(500)

    let pageWidth = window.innerWidth

    useEffect(() => {
        pageWidth < 750 && setLineWidth(200)
    }, [pageWidth])

    const [popupData, setPopupData] = useState(null)
    const [openPopup, setOpenPopup] = useState(false)

    const openPopupLarge = () => {
        setOpenPopup(true)
        document.body.classList.add("overflow--hidden")
    }

    const closePopup = () => {
        setOpenPopup(false)
        document.body.classList.remove("overflow--hidden")
    }
    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <section className='homeBanner'>
            <PopupLarge closePopup={closePopup} open={openPopup} data={popupData}/>
            <div className='homeBanner__info'>
                <h1 className='main-title'>Արագ վարկեր</h1>
                <h1 className='main-title'>Քեզ համար</h1>
                <div className='line'><img src={yellowLine}/></div>
                {/*<Lottie*/}
                {/*width={lineWidth}*/}
                {/*height={40}*/}
                {/*options={defaultOptions}*/}
                {/*/>*/}
                <p className='homeBanner__info__text '>Ընտրիր Քեզ անհրաժեշտ գումարը և մարման ժամկետը, ստացիր վարկի հաստատման մասին պատասխանը նույն պահին։</p>
            </div>
            <img src={banner} alt="banner" className='homeBanner__img'/>
            <div className='homeBanner__frame bannerFrame bannerFrame--leftTop'>
                <img src={smile} alt="smile icon"/>
                <div>
                    <span className='text-lg'>40k+</span>
                    <span className='text-sm'>Հաճախորդ</span>
                </div>
            </div>
            <div className='homeBanner__frame bannerFrame bannerFrame--rightTop'>
                <img src={badge} alt="badge icon"/>
                <div>
                    <span className='text-lg'>10+</span>
                    <span className='text-sm'>Տարվա փորձ</span>
                </div>
            </div>
            <div className='homeBanner__frame bannerFrame bannerFrame--leftBottom'>
                <img src={user} alt="badge icon"/>
                <div>
                    <span className='stars'> &#9733; &#9733; &#9733; &#9733; &#9733;</span>
                    <span
                        className='text-xs'>Կրեդո-ի հետ ունեցածս համագործակցությունը շատ արագ էր ու արդյունավետ:</span>
                </div>
            </div>
            <div className='homeBanner__frame bannerFrame bannerFrame--rightBottom'>
                <img src={envelope} alt="envelope icon"/>
            </div>
        </section>
    )
}
