import ChangePassword from "./components/ChangePassword";
import GuardedRoute from "./components/GuardedRoute";
import Messages from "./components/Messages";
import PersonalInfo from "./components/PersonalInfo";
import Profile from "./components/Profile";
import ProfileLoans from "./components/ProfileLoans";
import Suggestions from "./components/Suggestions";
import { Auth } from "./libs/Auth";
import Contact from "./pages/Contact";
import ForgetPasswordPage from "./pages/ForgetPasswordPage";
import Home from "./pages/Home";
import RequestSent from "./pages/RequestSent";
import Terms from "./pages/Terms";
import ProfileLoanDetails from "./components/ProfileLoanDetails";

const routes ={
    loggedInRoutes:[

        {
            path:'/profile',
            exact:true,
            element: <Profile />,
            items:[
                {
                    path:'loans',
                    exact:true,
                    element: <ProfileLoans />
                },
                {
                    path:'info',
                    exact:true,
                    element: <PersonalInfo />
                },
                {
                    path:'messages',
                    exact:true,
                    element:<Messages />
                },
                {
                    path:'suggestions',
                    exact:true,
                    element:<Suggestions />
                },
                {
                    path:'change-password',
                    exact:true,
                    element:<ChangePassword />
                }
            ]
        },
    ],
    simpleRoutes:[

        {
            path:'details',
            exact:true,
            element: <ProfileLoanDetails />
        },
        {
            path:'/terms',
            exact:true,
            element: <Terms />
        },
        {
            path:'/',
            exact:true,
            element: <Home />
        },
        {
            path:'/home/section/:section',
            exact:true,
            element: <Home />
        },
        {
            path:"/contactus",
            exact:true,
            element:<Contact />
        },
        {
            path:"/request-sent",
            exact:true,
            element:<RequestSent />
        },
        {
            path:"/forgot-password/:key",
            exact:true,
            element:<ForgetPasswordPage />
        },

    ]
}

export default routes