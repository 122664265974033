import {Api} from "../libs/Api";

export default class LoanFormService{
    static formData={};
    static setFormData(key,data){
        let oldData={...LoanFormService.formData};
        oldData[key]=data
        LoanFormService.formData=oldData;
    }
    static getFormData(key){
        let data=LoanFormService.formData;
        return data[key] || null;
    }
    static send=()=>{
        let data=LoanFormService.formData;
        let branch = JSON.parse(localStorage.getItem("branch"))
        let formData = data.filesData;
        branch && formData.append('company_id',JSON.stringify(branch.id))
        formData.append('loanInfo',JSON.stringify(data.loanInfo))
        formData.append('userInfo',JSON.stringify(data.userInfo))
        return Api.post('profile/add-loan',formData)
    }
}
