import React from 'react';
import { Navigate, Outlet} from "react-router-dom";
import {Auth} from "../libs/Auth";

const GuardedRoute = ({isGuest}) =>  {
    if(isGuest!==Auth.isGuest()){
        if(isGuest){
            return <Navigate to="/" />;
        }else{
            return <Navigate to="/" />;
        }
    }
    return <Outlet />;
}

export default GuardedRoute;