import React, {useEffect, useState} from 'react'
import "../assets/scss/signInPopup.scss"
import {useForm} from "react-hook-form";
import Close from "./Close";
import {Api} from "../libs/Api";
import "../assets/scss/cancelReason.scss";
import "../assets/scss/popupBranch.scss"
import { TailSpin } from 'react-loader-spinner'


export default function PopupBranches(props) {
    const [searchQuery, setSearchQuery] = useState('');
    const [branches,setBranches] = useState()

    useEffect(() => {
        Api.get('data/companies').then((res) => {
          setBranches(res)
        })
    },[])
    const filteredItems = branches && branches.filter(item =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );


    const handleSearch = (value) => {
        setSearchQuery(value);
    }
    const closePopup = () => {
        window.popupSetter({open:false,body:<PopupBranches />,})
    }

    const onSubmit = (data) => {
        localStorage.setItem("branch",data.selectedBranch)
        closePopup()
        props.changeStep()
    }
    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm({});

  return (
        <form className='branchesPopup cancelReason' onSubmit={handleSubmit(onSubmit)}>
            <div onClick={() => closePopup()}>
                <Close />
            </div>
            <p className="branchesPopup__text">Ընտրել Ապառիկի կետը</p>
            <div className="searchBox">
                <input className="searchBox__input"  placeholder="Որոնել"  onChange={(e) => handleSearch(e.target.value)} type="text"/>
            </div>

            <div className='popupBranch'>
                {!filteredItems ?
                    <div className={"flex-center"}>
                        <TailSpin
                            visible={true}
                            height="80"
                            width="80"
                            color="#A9B5BC"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                    </div>

                    :
                <div className='cancelReason__options popupBranch__content'>
                    {errors.selectedBranch && (
                        <span className="inputError" role="alert">
                    {errors.selectedBranch?.message}
                  </span>
                    )}
                    {filteredItems && filteredItems.map((item, index) => {
                        return <label className='cancelReason__options__item radioContainer' key={index}>
                            <input {...register("selectedBranch", {required: "Պարտադիր է ընտրել"})} value={JSON.stringify(item)} type="radio" id={"item_" + index}/>
                            <label htmlFor={"item_" + index}>{item.name}</label>
                            <span className="checkmark"></span>
                        </label>
                    })}
                </div>
                }
            </div>

            <div className="buttonsSection">
                <div className="buttonsSection__buttons">
                    <button onClick={() => closePopup()} value="Փակել" className='main-button main-button--withBorder'>Փակել</button>
                    <input type="submit" value="Հաստատել" className='main-button main-button--blue'/>
                </div>
            </div>
        </form>
  )
}
