import React, {useEffect, useRef, useState} from 'react'
import {useForm, Controller} from 'react-hook-form';
import InputMask from 'react-input-mask'
import {Api} from '../libs/Api';
import Select from 'react-select'
import "../assets/scss/personalInfo.scss"
import "../assets/scss/secondStep.scss"
import moment from 'moment';
import {
    validateDate,
    validatorEmail,
    validatorHayeren,
    validatorPhone,
    validatorRequired,
    validatorSocCard
} from "../libs/validators";
import {selectStyle} from "../libs/globalFunctions";
import LoanFormService from "../services/loanFormService";
import {Auth} from "../libs/Auth";
import {Link} from 'react-router-dom';
import SuccessChangeInfo from './SuccessChangeInfo';
import {userFields} from "../libs/datas";
import EmailVarificationPopup from './EmailVarificationPopup';
import AlreadyRegistered from "./AlreadyRegistered";

export default function PersonalInfo(props) {
    const isStepForm = props.isStepForm || 0;
    const [passportList, setPassportList] = useState([])
    const [dataReady, setDataReady] = useState(false)
    const [mobileView,setMobileView] = useState(false)
    const [isLoggedIn, setIsLoggedIn] = useState()
    const [isDisabled,setIsDisabled] = useState(false)


    let pageWidth = window.innerWidth
    const isGuest= !Auth.isGuest()

    useEffect(() =>{
        setIsLoggedIn(isGuest)
    },[isGuest])

    const {
        register,
        handleSubmit,
        formState: {errors},
        setValue,
        control,
        setError,
        clearErrors,
        setFocus
    } = useForm({mode: 'onTouched', criteriaMode: "all"});
    useEffect(() => {
        setFocus("mob_phone")
        Api.get(`data/get-library-data?library_id=2`, {}).then((data) => {
            setPassportList(data);
        })
    }, []);
    useEffect(() => {
        if (pageWidth<750) setMobileView(true)
       },[pageWidth])
    useEffect(() => {
        if (passportList.length) {
            prepareFormFileds();
            if(!Auth.isGuest()){
                Api.get('profile/get-user-data', {}).then((data) => {
                    initData(data);
                    localStorage.setItem("name",data.firstname)
                });
            }
        }
    }, [passportList]);
    let formFields = userFields;
    const prepareFormFileds = () => {
        for (let i in formFields) {
            for (let j in formFields[i]) {
                if(['soc_card','mob_phone','email'].includes(formFields[i][j]['key'])){
                    formFields[i][j]['validation']['validate']=(value)=>{
                        validateUnique(formFields[i][j]['key'],value)
                    }
                }
                switch (formFields[i][j]['key']) {
                    case 'doc_type':
                        formFields[i][j]['list'] = passportList;
                        formFields[i][j]['validation']['validate'] = validatePassport;
                        break;
                    case 'doc_num':
                        formFields[i][j]['validation']['validate'] = validatePassport;
                        break;
                    case 'doc_date':
                        formFields[i][j]['validation']['validate'] = validateStartDate;
                        break;
                    case 'doc_valid_date':
                        formFields[i][j]['validation']['validate'] = validateEndDate;
                        break;
                }
            }
        }
        setDataReady(true);
    }
    const initData = (data) => {
        for (let i in data) {
            switch (i) {
                case 'doc_type':
                    for (let j in passportList) {
                        let option = passportList[j];
                        if (parseInt(option.value) === parseInt(data[i])) {
                            setValue(i, option)
                        }
                    }
                    break;
                case 'doc_num':
                    setValue(i, data['doc_seria'] + data['doc_num'])
                    break;
                default:
                    setValue(i, data[i]);
                    break;
            }

        }
    }
    const onSubmit = async (data) => {
        let request_data = {...data};
        request_data.doc_type = data.doc_type.value;
        request_data.doc_type_label = data.doc_type.label;
        if (isStepForm) {
            Api.post('profile/validate-user-data', request_data).then((response) => {
                if (checkServerErrors(response)) {
                    LoanFormService.setFormData('userInfo', request_data);
                    !isLoggedIn ?
                    Api.post('profile/start-verify-email',{"email":data.email}).then(() =>{
                        setIsDisabled(true)
                        setTimeout(() => setIsDisabled(false),10000)
                        window.popupSetter({
                            open: true, body: <EmailVarificationPopup setCurrentStep={props.setCurrentStep} email={data.email} />,
                            popupClassContainer: 'popup__container--sm',
                            popupClassInfo: "popup__container__info--sm"
                        })
                    })
                    .catch((error) => console.log(error))
                        : props.setCurrentStep(3)
                }
            })
        } else {
            Api.post('profile/update-user-data', request_data).then((data) => {
                if (checkServerErrors(data)) {
                    window.popupSetter({
                        open: true, body: <SuccessChangeInfo/>,
                        popupClassContainer: 'popup__container--sm',
                        popupClassInfo: "popup__container__info--sm"
                    })
                }
            })
        }
    }
    const checkServerErrors = (data) => {
        if (data.hasOwnProperty('formError')) {
            for (let i in data.formError) {
                setError('root.' + i, {type: 'root', message: data.formError[i].join(', ')})
            }
            return false;
        }
        return true;
    }
    const validatePassport = (value, data) => {
        let regex;
        switch (data.doc_type?.value) {
            case "115":
                regex = /^[0]{1}[0-9]{8}$/
                break
            default:
                regex = /^[A-Za-z]{2}[0-9]{7}$/
                break;
        }
        clearErrors('_doc_num')
        if (data.doc_num.length && !regex.exec(data.doc_num)) {
            setError('_doc_num', {type: "format", message: "Անձնագրի ֆորմատի սխալ:"});

        } else if (!data.doc_num.length) {
            setError('_doc_num', {type: "format", message: "Պարտադիր է լրացնել:"});
        }
        validateUnique('doc_num',data.doc_num);
    }
    const validateStartDate = (value) => {
        let date = validateDate(value, {key: 'doc_date', setError, clearErrors});
        if (date) {
            if (date.format('YYYYMMDD') > moment().format("YYYYMMDD")) {
                setError('_doc_date', {type: 'custom', message: 'Սխալ ամսաթիվ:'})
            } else{
                // console.log(refs["doc_region_num"].fieldRef)

            }
            }

        // setFocus("doc_region_num")
        // var end_date = date.add(10, 'year').format("DD/MM/YYYY");
        //     setValue('doc_valid_date', end_date)
        //     validateEndDate(end_date)
    }
    const validateEndDate = (value) => {
        let date = validateDate(value, {key: 'doc_valid_date', setError, clearErrors});
        if (date) {
            if (date.format('YYYYMMDD') < moment().format("YYYYMMDD")) {
                setError('_doc_valid_date', {type: 'custom', message: 'Վավերականութան ժամկետն անցել է:'})
            }
        }
    }
    let refs={};
    const validateUnique = (key,inputValue)=>{
        if(["email" , "mob_phone" , "soc_card" , "doc_num"].includes(key)){
            Api.post('data/check-unique-attribute', {key:key,value:inputValue}).then((data) => {
                console.log("response",data)
                    if(data.hasOwnProperty("formError")){
                        window.popupSetter({
                            open: true, body: <AlreadyRegistered/>,
                            popupClassContainer: 'popup__container--sm',
                            popupClassInfo: "popup__container__info--sm"
                        })
                    }
            })
        }

    }
    const drawField = (field, index) => {
        let elem;
        let type = field.type || 'text';
        const { ref, ...rest } = register(field.key);
        refs[field.key]=ref;
        // console.log(...register(field.key, field.validation));
        switch (type) {
            case "text":
                elem = <input
                    type="text"
                    className="formInput__input"
                    placeholder={field.placeholder}
                    {...register(field.key, field.validation)}
                    // onBlur={(e) => validateUnique(field.key,e.target.value)}
                    maxLength={field?.size}

                />;
                break;
            case 'masked':
                elem = <Controller
                    render={(props) => {
                        return <InputMask {...props.field} className="formInput__input" mask={field.mask} {...register(field.key, field.validation)}  />
                    }}
                    name={field.key}
                    control={control}
                    defaultValue=""
                />;
                break;
            case 'list':
                elem = <Controller
                    render={(props) => {
                        return <Select options={field.list} styles={selectStyle}  {...register(field.key, field.validation)} {...props.field} />
                    }}
                    name={field.key}
                    control={control}
                    defaultValue=""
                />;
                break;
            default:
                elem = ''
                break;
        }
        return <div className="formInput" key={index}>
            <label htmlFor={field.key} className="formInput__label">
                {field.label} {field.validation?.required ? "*" : ""}
            </label>
            {elem}
            <span className="inputError" role="alert">
                  {errors[field.key] && errors[field.key].message}
                {errors['_' + field.key] && errors['_' + field.key].message}
                {errors.root && errors.root[field.key] && errors.root[field.key].message}
            </span>
        </div>
    }
    return (
        <>
            {dataReady > 0 &&
                <form onSubmit={handleSubmit(onSubmit)} className={isStepForm && "secondForm"}>
                    {!isStepForm && <h4 className='text-blue text-blue--lg personalInfo__title hide_sm'>Անձնական տվյալներ</h4>}
                    <div className='secondStep'>
                        <div className='personalInfo'>
                            <h6 className='subtitle personalInfo__subtitle'>Անձնական տվյալներ</h6>
                            <div className='personalInfo__fields'>
                                {formFields.userFields.map(drawField)}
                            </div>

                            <h6 className='subtitle personalInfo__subtitle'>Անձը հաստատող փաստաթուղթ</h6>
                            <div className='personalInfo__fields'>
                                {formFields.passportFields.map(drawField)}
                            </div>
                        </div>
                    </div>
                    {props.isStepForm && 
                    <div className='popup-bottom'>
                        <span className='text-blue-sm text-blue-sm--pointer text-blue-sm--gray'
                              onClick={() => props.closePopup()}>Չեղարկել</span>
                        <div>
                            {mobileView ? 
                            <button type={"button"} className='main-button--mobBlue mobileBtnWhiteBlue' onClick={() => {props.setCurrentStep(1)}} /> :   
                            <button type={"button"}
                                    className='main-button main-button--xl-md popup-bottom__buttonBack text-blue-sm text-blue-sm--pointer'
                                    onClick={() => {
                                        props.setCurrentStep(1)
                                    }}>Նախորդ
                            </button>}
                            {mobileView ? <button disabled={isDisabled} className='main-button--mobile mobileBtnGradient' /> :<button disabled={isDisabled} className='main-button main-button--xl-md main-button--gradient popup-bottom__button'>Շարունակել</button>}
                        </div>
                    </div>
                    }
                    {!props.isStepForm && <div className='personalInfo__bottom'>
                        <input
                            className="main-button main-button--blue main-button--lg"
                            type="submit"
                            value="Պահպանել"
                        />
                        <Link to="/profile/change-password" className='text-blue-sm text-blue-sm--pointer '>Փոխել
                            գաղտնաբառը</Link>
                    </div>}
                </form>
            }
        </>

    )
}
