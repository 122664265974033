import React, {useEffect, useState} from 'react'
import "../assets/scss/header.scss"
import SignInPopup from '../components/SignInPopup'
import {Auth} from '../libs/Auth'
import {Link, useNavigate} from 'react-router-dom';
import logo from "../assets/img/logo.svg"
import MainButton from '../components/MainButton'
import {useLocation} from 'react-router-dom';
import login from "../assets/img/login.svg"


export default function Header() {
    const [isLoggedIn, setIsLoggedIn] = useState()
    const [isMobile, setIsMobile] = useState(false)
    const [loc, setLoc] = useState()

    const navigate = useNavigate()
    const location = useLocation();
    let pageWidth = window.innerWidth
    const isGuest = Auth.isGuest()

    useEffect(() => {
        setLoc(location.pathname)
    }, [location])

    useEffect(() => {
        pageWidth < 600 && setIsMobile(true)
    }, [pageWidth])

    useEffect(() => {
        setIsLoggedIn(isGuest)
    }, [isGuest])

    const openSignInPopup = () => {
        window.popupSetter({open: true, body: <SignInPopup/>})
    }
    const goToProfile = () =>{
        navigate("/profile/loans");
        let sidebar = document.getElementById('profileSidebar');
        if(sidebar){
            sidebar.classList.add("open");
            document.body.classList.add("overflow--hidden")
        }
    }

    return (
        <header className='container'>
            <div className='header '>
                <Link to='/' className='header__logo'>
                    <img src={logo} alt="logo" className='header__logo__img'/>
                </Link>
                <nav>
                    <ul className='header__menu'>
                        {/*<Link to="/home/section/about" className='header__menu__item'>*/}
                            <Link  className='header__menu__item' to="https://www.credofinance.am/about/">
                            <li>Մեր մասին</li>
                            </Link>
                        <Link to="/contactus" className={`header__menu__item ${loc === "/contactus" ? `active` : ""}`}>
                            <li>Կապ</li>
                        </Link>
                        {
                            !isGuest ?
                                <>
                                    <span  onClick={() => goToProfile()}>
                                        <li className='header__menu__btn'>
                                            <span>{localStorage.getItem("name")}</span>
                                        </li>
                                    </span>
                                </>
                                :
                                <>
                                    {/*<div className={"show_sm header__menu__mbBtn" }>*/}
                                    {/*    <img onClick={() => openSignInPopup()} src={login} alt="login icon"/>*/}
                                    {/*    <span>Մուտք</span>*/}

                                    {/*</div>*/}
                                    <li onClick={() => openSignInPopup()}><MainButton text="Մուտք"/></li>
                                </>
                        }
                    </ul>
                </nav>
            </div>
        </header>
    )
}
