import React, {useEffect, useState} from 'react'
import "../assets/scss/forgotPassword.scss"
import icon from "../assets/img/popups/announcement.svg"
import arrow from "../assets/img/popups/arrowBlue.svg"
import {useForm} from 'react-hook-form'
import emailIcon from "../assets/img/popups/email.svg"
import {Api} from '../libs/Api'
import SignInPopup from './SignInPopup'

export default function ForgotPassword(prop) {
    const [success, setSuccess] = useState(false)
    const [isMobile, setIsMobile] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false)


    let pageWidth = window.innerWidth

    useEffect(() => {
        if (pageWidth < 750) {
            setIsMobile(true)
        }
    }, [pageWidth])

    const {
        register,
        handleSubmit,
        formState: {errors},
        setError
    } = useForm({});

    const openSignInPopup = () => {
        window.popupSetter({open: true, body: <SignInPopup/>}, {open: false, body: <ForgotPassword/>})
    }

    const onSubmit = (data) => {
        setIsDisabled(true)
        setTimeout(() => setIsDisabled(false), 10000);
        const email = data.email;
        Api.post('profile/forgot-password', {
            "email": email,
        }).then(function (response) {
            if (response.formError?.username) {
                setSuccess(false)
                setError('root.validationPhoneError', {
                    type: response.formError?.username[0],
                })
            } else if (response.formError?.email) {
                setSuccess(false)
                setError('root.validationEmailError', {
                    type: response.formError?.email[0],
                })
            } else if (response.msg === "ok") {
                setSuccess(true)
            }
        })
            .catch(function (error) {
                console.log("error", error)
            })
    }
    return (
        <div className='forgotPassword'>
            <div className='forgotPassword__left'>
                <img src={icon} alt="announcement icon"/>
                <div className='forgotPassword__left__text'>
                    {success ? (
                            <p className='text-light'>Հարգելի’ Հաճախորդ,
                                <br/>
                                սեղմելով նամակում դրված հղումը, Դուք կվերադառնաք կայք և կշարունակեք գաղտնաբառի վերականգման
                                գործընթացը։</p>
                        ) :
                        (<p className='text-light text-justify'>Հարգելի’ Հաճախորդ,
                            <br/>
                            գաղտնաբառի վերականգման համար խնդրում ենք մուտքագրել Ձեր էլ. փոստին, որը սկզբնական գրանցման
                            ժամանակ նշել էիք Ձեր դիմումի մեջ։</p>)
                    }
                </div>
                {!isMobile && <div className='forgotPassword__left__prev'>
                    <img src={arrow} alt="blue arrow"/>
                    <span onClick={() => openSignInPopup()} className='text-blue-sm'>Վերադառնալ</span>
                </div>}
            </div>
            <div className='forgotPassword-r'>
                {(success === true) ?
                    <div className='forgotPassword__right'>
                        <img src={emailIcon} alt="email icon"/>
                        <h4 className='forgotPassword__right__title'>Գաղտնաբառի վերականգման նամակը ուղարկված է Ձեր
                            Էլեկտրոնային փոստին։</h4>
                        <span className='text-gray-xs'>Չե՞ք կարողանում գտնել, խնդրում ենք ստուգել «Spam» բաժնում։</span>
                    </div>
                    :
                    (<form onSubmit={handleSubmit(onSubmit)} className="forgotPassword__right">
                        <span className='forgotPassword__right__title'>Վերականգնում</span>
                        <div className="formInput">
                            <label htmlFor="message" className="formInput__label">
                                Էլ. փոստ
                            </label>
                            <input
                                type="email"
                                className="formInput__input"
                                {...register("email", {
                                    required: "Պարտադիր է լրացնել:",
                                })}
                            />
                            <span>
                {errors.email && (
                    <span className="inputError" role="alert">
                    {errors.email.message}
                  </span>
                )}
              </span>
                            <span>
                {errors.root?.validationEmailError?.type && <span className="inputError" role="alert">
                    {errors.root?.validationEmailError?.type}
              </span>}
              </span>
                        </div>
                        <div className="forgotPassword__btn">
                            <input
                                className="main-button main-button--blue"
                                type="submit"
                                value="Ուղարկել"
                                disabled={isDisabled}
                            />
                        </div>
                        {isMobile && <span onClick={() => openSignInPopup()} className='text-blue-sm'>Վերադառնալ</span>}

                    </form>)
                }
            </div>
        </div>
    )
}
