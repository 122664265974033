import React, {useEffect, useState, useRef} from 'react'
import message from "../assets/img/popups/messageImg.png"
import InputMask from 'react-input-mask'
import "../assets/scss/smallPopup.scss"
import {useNavigate} from 'react-router'
import {Api} from '../libs/Api'
import Close from "./Close";


export default function EmailVarificationPopup(props) {
    const [arrCode, setArrCode] = useState([...Array(4)])
    const [error, setError] = useState()
    const [isDisabled, setIsDisabled] = useState(true)
    const navigate = useNavigate()


    const writeNumbers = (e,i) => {
        setError("")
        let value=e.target.value
        arrCode[i]=value
        if (e.keyCode === 13 || e.target.value !== " " ) {
            // Focus on the next sibling
             e.target.nextElementSibling.focus()
        }else if (e.keyCode === 8 || e.target.value !== " " ) {
            // Focus on the previous sibling
            e.target.previousSibling.focus()
        }
    }
    useEffect(() => {
        console.log("props", props.setCurrentStep)
        arrCode.length < 4 || arrCode.includes(" ") ? setIsDisabled(true) : setIsDisabled(false)
    }, [arrCode])
    const closePopup = () => {
        window.popupSetter({open: false, body: <EmailVarificationPopup/>,})
    }
    const resendCode = () => {
        Api.post('profile/start-verify-email', {"email": props.email}).then(() => {
            window.popupSetter({
                open: true, body: <EmailVarificationPopup setCurrentStep={props.setCurrentStep} email={props.email}/>,
                popupClassContainer: 'popup__container--sm',
                popupClassInfo: "popup__container__info--sm"
            })
        })
            .catch((error) => console.log(error))
    }
    const onSubmit = () => {
        Api.post('profile/verify-email', {
            "code":arrCode.join(""),
            "email": props.email,
        }).then(function (response) {
            console.log(response)
            if (response.hasOwnProperty('formError')) {
                setError(response.formError.code.join(', '));
            } else {
                props.setCurrentStep(3)
                window.popupSetter({open: false, body: <EmailVarificationPopup/>})
            }
            if (response.respcode === -1) {
                setError("Սերվերի սխալ, կապվեք ադմինիստրատորի հետ");
            }

        })
            .catch(function (error) {
                setError(error)
            })
    }
    return (
        <div className='smallPopup'>
            <div onClick={() => closePopup()}>
                <Close/>
            </div>
            <img src={message} alt="message icon"/>
            <h4 className='smallPopup__title'>Նույնականացում</h4>
            <p className='text-light'>Շարունակելու համար խնդրում ենք լրացնել <span
                className='text-light--reg'>{props.email}</span> էլ. փոստին <br/>
                ուղղարկված կոդը:</p>
            <span className='smallPopup__smtext'>Չե՞ք կարողանում գտնել, խնդրում ենք ստուգել «Spam» բաժնում։</span>
            <div className='smallPopup__content'>
                <div className='smallPopup__content__span'>
                    {
                        [...Array(4)].map((item,index) => {
                            return <InputMask  mask="9" maskChar=" " onKeyUp={(e) => writeNumbers(e,index)} />
                        })
                    }
                </div>
            </div>
            <div className='smallPopup__bottom'>
                <a href='#' onClick={() => resendCode()}>նորից ուղղարկել</a>
                {error && <span className="inputError" role="alert">{error}</span>}
                <input onClick={() => onSubmit()} type="submit" value="Հաստատել" disabled={isDisabled}/>
            </div>
        </div>
    )
}
