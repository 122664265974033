import React, {useEffect, useRef, useState} from 'react'
import "../assets/scss/thirdStep.scss"
import passport from "../assets/img/formSteps/passport.png"
import selfie from "../assets/img/formSteps/selfie.png"
import soc from "../assets/img/formSteps/soc.png"
import army_passport from "../assets/img/formSteps/army_passport.png"
import FileExamplePopup from './FileExamplePopup'
import eye from "../assets/img/popups/eye.svg"
import cross from "../assets/img/popups/cross.svg"
import LoanFormService from "../services/loanFormService";


export default function ThirdStep(props) {
    const [selectedFiles, setSelectedFiles] = useState({})
    const [fileErrors, setFileErrors] = useState({})
    const [mobileView, setMobileView] = useState(false)

    let pageWidth = window.innerWidth

    const data = [
        {
            type: "Անձը հաստատող փաստաթուղթ",
            file: passport,
            key: 'photo_passport',
            required: true,
            id: 0
        },
        {
            type: "Ձեր նկարը՝ անձը հաստատող փաստաթուղթը (նկարի էջով) դեմքի կողքը պահված",
            file: selfie,
            key: 'photo_face',
            required: true,
            id: 1
        },
        {
            type: "Սոցիալական քարտ",
            file: soc,
            key: 'photo_soc',
            required: true,
            id: 2
        },
        {
            optionalText: "Եթե ուժային կառույցի աշխատակից եք, ներբեռնեք Ծառայողական վկայականը",
            type: "Ծառայողական վկայական",
            file: army_passport,
            key: 'photo_other',
            required: false,
            id: 3
        }
    ]
    const handleselectedFiles = (event, id) => {
        let newFile = {...selectedFiles}
        console.log("file", newFile)
        newFile[id] = event.target.files[0];
        setSelectedFiles(newFile);
        setFileErrors({...fileErrors, [id]: null})
    };
    const openFileExample = (file) => {
        window.popupSetter({open: true, body: <FileExamplePopup file={file}/>})
    }
    const deleteFile = (id, required) => {
        let newFile = {...selectedFiles}
        newFile[id] = null
        setSelectedFiles(newFile);
        let idName = "inputFile" + id
        document.getElementById(idName).value = "";
        if (required) {
            setFileErrors({...fileErrors, [id]: 'Պարտադիր է կցել ֆայլը'})
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        let errorList = {};
        let hasError = false;
        data.map((item, index) => {
            if (item.required && !selectedFiles[item.id]) {
                errorList[item.id] = 'Պարտադիր է կցել ֆայլը';
                hasError = true;
            }
        })
        setFileErrors(errorList);
        if (!hasError) {
            let formData = new FormData(event.target);
            LoanFormService.setFormData('filesData', formData);
            props.setCurrentStep(4);
        }
    }
    useEffect(() => {
        if (pageWidth < 750) setMobileView(true)
    }, [pageWidth])

    return (
        <form className='thirdForm' onSubmit={handleSubmit} encType={"multipart/form-data"}>
            <div className='thirdStep'>
                <div className='thirdStep__top'>
                    <h6 className='subtitle'>Հարգելի՛ Հաճախորդ, նույնականացման նպատակով անհրաժեշտ է դիմումին կցել հետևյալ
                        փաստաթղթերը`</h6>
                    <span className='text-sm'>(jpg, png, pdf ֆորմատի, առավելագույնը 5մբ)</span>
                </div>
                <div className='thirdStep__list'>
                    {
                        data.map((item, i) => {
                            return (
                                <div className='thirdStep__list__item' key={item.id}>
                                    {item?.optionalText && <span
                                        className='text-light text-light--semibold opt-text'>{item.optionalText}</span>}
                                    <div className='fileType'>
                                        <span className='text-light text-light--semibold'>{item.type}</span>
                                        <span
                                            className='text-blue-sm text-blue-sm--underline text-blue-sm--xs text-blue-sm--pointer example'
                                            onClick={() => openFileExample(item?.file)}>Դիտել նմուշը</span>
                                    </div>
                                    <div className='fileSelected'>
                                        <input name={item.key} type="file" accept={"image/*,.pdf"}
                                               id={`inputFile` + item.id}
                                               className={`custom-file-input ${selectedFiles[item.id] && 'hidden'}`}
                                               onChange={(e) => handleselectedFiles(e, item.id)}/>
                                        {selectedFiles[item.id] &&
                                            <div
                                                className='fileSelected__name text-blue-sm text-blue-sm--pointer text-blue-sm--bl text-blue-sm--xs'>
                            <span onClick={() => openFileExample(selectedFiles[item.id])}>
                                <span>{selectedFiles[item.id]['name'] + " "}</span>
                                <span
                                    className='text-blue-sm--light'>({(selectedFiles[item.id].size / 1024 / 1024).toFixed(2)} MB)</span>
                                <img src={eye} alt="eye icon"/>
                            </span>
                                                <img src={cross} alt="cross icon"
                                                     onClick={() => deleteFile(item.id, item.required)}/>
                                            </div>}
                                    </div>
                                    {fileErrors[item.id] && <span className="inputError" role="alert">
                                          {fileErrors[item.id]}
                                    </span>}
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className='popup-bottom'>
                <span className='text-blue-sm text-blue-sm--pointer text-blue-sm--gray'
                      onClick={() => props.closePopup()}>Չեղարկել</span>
                <div>
                    {mobileView ?
                        <button type={"button"} className='main-button--mobBlue mobileBtnWhiteBlue' onClick={() => {
                            props.setCurrentStep(2)
                        }}/> :
                        <button type={"button"}
                                className='main-button main-button--xl-md popup-bottom__buttonBack text-blue-sm text-blue-sm--pointer'
                                onClick={() => {
                                    props.setCurrentStep(2)
                                }}>Նախորդ</button>}
                    {mobileView ? <button className='main-button--mobile mobileBtnGradient'/> : <button
                        className='main-button main-button--xl-md main-button--gradient popup-bottom__button'>Շարունակել</button>}
                </div>
            </div>
        </form>
    )
}
