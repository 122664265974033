import React from 'react'
import "../assets/scss/main-button.scss"

export default function MainButton(props) {
  return (
      <div className='main-button main-button--gradient main-button-mb'>{props.text}</div>
  )
}






