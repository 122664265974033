import React from 'react'
import Close from './Close';


export default function Popup({
    setter,
    popupData
}

) {
window.popupSetter = setter;
const handleClose = () => setter({open:false,title:'',body:''});
if(popupData.open){
  return (
    <div className={"popup active "}>

      <div className="popup__background" onClick={() => handleClose()}></div>
      <div className={"popup__container " + (popupData.popupClassContainer || '')}>
        <div className="closeLayer" onClick={() => handleClose()}></div>
        <div className={"popup__container__info " + (popupData.popupClassInfo || '')}>
          <div onClick={() => handleClose()}>
            {!popupData.popupClassContainer && <Close /> }  
          </div>
          {popupData.body}
        </div>
      </div>
    </div>
  );
}
return <div></div>
}