import React, { useState } from 'react'
import cover from "../assets/img/about/cover.png"
import minus from "../assets/img/about/minus.svg"
import "../assets/scss/about.scss"


export default function About() {
    const [showHistory,setShowHistory] = useState(true)
    const [showGoals, setShowGoals] = useState(true)

  return (
        <section className='about' id='aboutSection'>
            <div className='about__img'>
                <img src={cover} alt="cover" />
            </div>
            <div className='about__info'>
                <h3 className='about__info__title'>Մեր մասին</h3>
                <div className='about__info__history'>
                    <div className='history'>
                        <div className={`history__minusIcon ${showHistory && "history__minusIcon expanded" }`} onClick={() => setShowHistory(!showHistory)}>
                            <img src={minus} alt="minus icon" />
                        </div>
                        <h5 className='history__subtitle'>Մեր պատմությունը</h5>
                    </div>
                    {
                        showHistory && 
                    <div className='historyText text-justify'>
                        Կրեդո Ֆինանս ունիվերսալ վարկային կազմակերպությունը գրանցվել և լիցենզավորվել է
                        ՀՀ Կենտրոնական Բանկի Խորհրդի 2013 թ. փետրվարի 5-ի թիվ 30Ա որոշմամբ (լիցենզիա թիվ՝ 38, 
                        գրանցման վկայական՝ 169): Ընկերությունը ունի 5 մասնաճյուղ, որոնք գտնվում են Երևանում։
                    </div>
                    }
                </div>
                <div className='about__info__history'>
                    <div className='history'>
                        <div className={`history__minusIcon ${showGoals && "history__minusIcon expanded" }`} onClick={() => setShowGoals(!showGoals)}>
                            <img src={minus} alt="minus icon" />
                        </div>
                        <h5 className='history__subtitle'>Մեր նպատակը</h5>
                    </div>
                    {
                        showGoals && 
                    <div className='historyText text-justify'>
                        Սպառողների հանդեպ ցուցաբերել անհատական մոտեցում,
                        տրամադրել ճկուն և հարմարավետ ծառայություններ:
                    </div>
                    }
                </div>
            </div>
        </section>
  )
}
