import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import "../assets/scss/cancelReason.scss"
import { Api } from '../libs/Api';
import Close from "./Close";

export default function CancelReasonPopup() {

    const reasons=[
        "Գործարքը դադարեցնում եմ պայմանների պատճառով",
        "Պետք է ճշտեմ որոշ տվյալներ",
        "Չկարողացա լրացնել",
        "Այլ պատճառ"
    ];

    const closePopup = () => {
        window.popupSetter({open:false,body:<CancelReasonPopup />,})
    }
    const {
        handleSubmit,
        register,
        formState: { errors },
      } = useForm({});
      const onSubmit = async (data) => {
        Api.post('data/leave-reason',{
            "reason":data.reason,
            "phone":data.phone
          }).then(function(response){
            window.popupSetter({open:false,body:<CancelReasonPopup />,})
          })
          .catch(function(error){
            console.log("error", error)
          })
      }
      
  return (
    <form className='cancelReason' onSubmit={handleSubmit(onSubmit)} >
        <div onClick={() => closePopup()}>
            <Close />
        </div>
        <span className='cancelReason__subtitle'>Ձեր կարծիքը կարևոր է մեզ համար:</span>
        <h5 className='cancelReason__title'>Խնդրում ենք նշել դիմումը անավարտ թողնելու պատճառը.</h5>
        <div className='cancelReason__options'>
            {reasons.map((item,index)=>{
                return <label className='cancelReason__options__item radioContainer' key={index}>
                <input {...register("reason")} value={item} type="radio"  id={"item_"+index}/>
                <label for={"item_"+index}>{item}</label>
                <span className="checkmark"></span>
            </label>
            })}
        </div>
            <div className='cancelReason__input'>
              <p>Նշեք Ձեր հեռախոսահամարը և մեր մասնագետը կապ կհաստատի Ձեզ հետ:</p>
              <input type="tel" {...register("phone",{ required: "Պարտադիր է լրացնել" })} />
              {/* {errors.phone.message && <span className="inputError" role="alert">{errors.phone.message}</span>} */}
            </div>
        <input type="submit" value="Ուղարկել" className='main-button main-button--blue'/>
    </form>
  )
}
