import React from 'react'
import "../assets/scss/steps.scss"
export default function StepNavigation(props) {


    let currentStep = props.currentStep
    let stepsCount = 5
    let items=[];
    let  labels=[
        '',
        'Գումար',
        'Տվյալներ',
        'Փաստաթղթեր',
        'Հաստատում',
        'Պայմաններ'
    ];
    for(let i=1; i<=stepsCount;i++){
        let  n_class='';
        if(currentStep>i){
            n_class='passedStep';
        }else if(currentStep===i){
            n_class='currentStep';
        }
        if(i>1){
            items.push(<span key={i+'step_divider'} className={'stepDivider '+n_class}></span>);
        }
        items.push(<span key={i+'step_point'} className={'stepPoint '+n_class}><span className={'stepNumber'}>{i}</span><span className={"stepPointLabel"}>{labels[i]}</span></span>);
    }
    return (
         <div className={'stepsWrapper'} >
            {items}
        </div>
            
    )
}






