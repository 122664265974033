import React, { useState } from 'react'
import GoogleMapReact  from 'google-map-react';
import MapPin from './MapPin';
import "../assets/scss/mapSection.scss"
import pin from "../assets/img/contact/pin.svg"
import phone from "../assets/img/contact/phone.svg"
import arrow from "../assets/img/contact/arrDown.svg"


export default function Map() {
  const [showBranches,setShowBranches] = useState(false)

  const [centerLat,setCenterLat] = useState( 40.1758427)
  const [centerLng,setCenterLng] = useState( 44.4910669)
  const [centerZoom,setCenterZoom] = useState( 12)

  function createMapOptions () {
    var styles = [
      {
        "featureType": "administrative",
        "elementType": "geometry",
      },
      {
        "featureType": "administrative.country",
        "elementType": "labels",
      },
      {
        "featureType": "administrative.country",
        "elementType": "labels.text",
      },
      {
        "featureType": "administrative.province",
        "elementType": "labels.text",
      },
      {
        "featureType": "administrative.locality",
        "elementType": "labels.text",
      },
      {
        "featureType": "administrative.neighborhood",
        "elementType": "labels.text",
      },
      {
        "featureType": "landscape",
        "elementType": "geometry",
      },
      {
        "featureType": "poi",
        "elementType": "all",
      },
      {
        "featureType": "poi.park",
        "elementType": "all",
      },
      {
        "featureType": "road",
        "elementType": "geometry.stroke",
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry",
      },
      {
        "featureType": "road.arterial",
        "elementType": "geometry",
      },
      {
        "featureType": "road.local",
        "elementType": "geometry",
      },
      {
        "featureType": "transit",
        "elementType": "geometry",
      },
      {
        "featureType": "water",
        "elementType": "geometry",
      }
    ];
    return {
      featureType: "all",
      elementType: "labels.text.fill",
      styles: styles
    }
  }
    const branches = [
      {
        name:"Աջափնյակ մասնաճյուղ",
        address:"ՀՀ, ք.Երևան, Բաշինջաղյան 192",
        phone:"060 465555, 098 416282",
        lat:40.208891,
        lng:44.4688711
      },
      {
        name:"Շենգավիթ մասնաճյուղ",
        address:"ՀՀ, ք.Երևան,Բագրատունյանց պող. 6շ 1ա",
        phone:"060 465555, 091 220182",
        lat:40.1545214,
        lng:44.47853
      },
      {
        name:"Էրեբունի մասնաճյուղ",
        address:"ՀՀ, ք.Երևան,Տիգրան Մեծի 69, 1/2 տարածք",
        phone:"060 465555, 098 045466",
        lat:40.1562125,
        lng:44.510759
      },
      {
        name:"Մալաթիա մասնաճյուղ",
        address:"ՀՀ, ք.Երևան, Անդրանիկի 147",
        phone:"060 465555, 077 461460",
        lat:40.1655009,
        lng:44.4415305
      },
      {
        name:"Նոր Նորք մասնաճյուղ",
        address:"ՀՀ, ք.Երևան, Ա. Միկոյան 21/2",
        phone:"060 465555, 099 465559",
        lat:40.1824432,
        lng:44.5693494
      },
    ]  
    const toggle = () => {
      setShowBranches(!showBranches)
    }

  const handlePinClick = (lat,lng) =>{
    setCenterLat(lat);
    setCenterLng(lng);
    setCenterZoom(16);
  }

  return (
    <section className='mapSection'>
    <div className='mapSection__map'>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyBqzROuzW7vmvfWs8I7l7_wrlj-Pt8Zx_M", language: 'en',libraries: ['places', 'drawing'], }}
        defaultCenter={{lat: 40.1758427, lng: 44.4910669}}
        center={{lat: centerLat, lng: centerLng}}
        defaultZoom={12}
        zoom={centerZoom}
        yesIWantToUseGoogleMapApiInternals
        heatmapLibrary={true}
        options={createMapOptions}
      >
        {branches.map((item) => ( 
        <MapPin lat={item.lat} lng={item.lng} text={item.name} key={item.lat} />
        ))}
      </GoogleMapReact>
    </div>
    <div className='mapSection__layer'>
        {
          branches.map((branch) => (
            <div className='mapSection__layer__items'>
              <h6 onClick={() => handlePinClick(branch.lat,branch.lng)}>{branch.name}</h6>
              <div>
                <img src={pin} alt="map pin"/>
                <span>{branch.address}</span>
              </div>
              <div>
                <img src={phone} alt="phone icon"/>
                <span>{branch.phone}</span>
              </div>
            </div>
          ))
        }
    </div>
    <div className={`mapSection__mobile ${showBranches && "full-height"} `}>
      <div className={`mapSection__mobile__content`}>
        <div className='mapSection__mobile__content__top' onClick={() => toggle()}>
          <span>Հասցեներ</span>
          <img src={arrow} alt="down arrow" />
        </div>
        {
          branches.map((branch) => (
            <div className='mapSection__layer__items'>
              <h6>{branch.name}</h6>
              <div>
                <img src={pin} alt="map pin"/>
                <span>{branch.address}</span>
              </div>
              <div>
                <img src={phone} alt="phone icon"/>
                <span>{branch.phone}</span>
              </div>
            </div>
          ))
        }
      </div>
    </div>
    </section>
  )
}
