import React from 'react'
import message from "../assets/img/contact/messagePopup.png"
import AlreadyRegistered from "./AlreadyRegistered";
import Close from "./Close";


export default function SuccessChangeInfo() {
    const closePopup = () => {
        window.popupSetter({open:false,body:<SuccessChangeInfo />,})
    }

    return (
    <div className='smallPopup'>
        <div onClick={() => closePopup()}>
            <Close />
        </div>
        <img src={message} alt="message icon"/>
        <h5 className='smallPopup__title'>Շնորհակալություն</h5>
        <p className='text-light'>Ձեր կատարած փոփոխությունները պահպանվել են։</p>
        <div className='smallPopup__content'></div>
    </div>
  )
}
