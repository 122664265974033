import React, {useEffect, useState} from 'react'
import {Api} from '../libs/Api';
import "../assets/scss/fifthStep.scss"
import arrow from "../assets/img/popups/downArrow.svg"
import "../assets/scss/stepPopup.scss"
import printer from "../assets/img/popups/printer.svg"
import ConfirmSMSPopup from './ConfirmSMSPopup';
import LoanFormService from '../services/loanFormService';

export default function FifthStep(props) {
    const [text, setText] = useState()
    const [active, setActive] = useState(1)
    const [error, setError] = useState()
    const [isMobile, setIsMobile] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false)
    const [checkList, setChecklist] = useState([
        // {
        //     value: "Տեղյակ եմ, որ նոր վարկի համար ԱՔՌԱ վարկային բյուրո կատարված ցանկացած հարցում իջեցնում է սքոր գնահատականի ցուցանիշը:",
        //     checked: false,
        //     id: 0,
        // },
        {
            value: "Ծանոթացել եմ, ամբողջապես ընդունում եմ և համաձայն եմ:",
            checked: false,
            id: 1,
        },
        {
            value: "Համաձայն եմ, որ «Կրեդո Ֆինանս» ՈՒՎԿ ՓԲԸ-ն օգտագործի մուտքագրված տվյալները` ծառայություններ մատուցելու նպատակով:",
            checked: false,
            id: 2
        },
        {
            value: "Համաձայն եմ, որ «Կրեդո Ֆինանս» ՈՒՎԿ ՓԲԸ-ի կողմից վարկային դիմում ստանալիս և/կամ վարկային պայմանագիր կնքելու դեպքում, տվյալ վարկային պայմանագրի գործողության ողջ ընթացքում ցանկացած պահի, առանց ինձ նախապես տեղյակ պահելու, կատարի բոլոր վերը նշված կետերի հարցումները։",
            checked: false,
            id: 3
        }

    ])

    const pageWidth = window.innerWidth

    useEffect(() => {
        pageWidth < 750 && setIsMobile(true)
    }, [pageWidth])


    useEffect(() => {
        Api.get(`data/get-page-content`, {id: 324}).then((data) => {
            setText(data.html)
        })
    }, []);

    const scrollToSection = (id) => {
        const section = document.getElementById(`` + id);
        section.scrollIntoView({behavior: 'smooth', block: "center"});
        setActive(id)
    }

    const scrollDown = () => {
        document.querySelector(".fifthStep__content__withScroll").scrollTo(0, document.querySelector(".fifthStep__content__withScroll").scrollHeight - 470, "smooth");
    }

    const contentScroll = () => {
        const contentScroll = document.getElementById("content").scrollTop
        let activeSectionId = conditions[0].id;
        let topHeight = document.getElementById("fifthStep__content__top").clientHeight;


        for (let section of conditions) {

            let sectionOffset = document.getElementById(section.id).offsetTop - 245;
            if (sectionOffset < contentScroll) {
                activeSectionId = section.id;

            } else {
                break;
            }
        }
        setActive(activeSectionId)

    }

    const printDocument = (el) => {
        let ww = Screen.availWidth;
        let wh = Screen.availHeight - 90;
        let htmlContent = document.getElementsByClassName(el)[0].innerHTML;
        let pw = window.open("", "newWin", "width=" + ww + ",height=" + wh);
        pw.document.write('<html><title>Printed Page</title><body>');
        pw.document.write('</head><body>');
        pw.document.write(htmlContent);
        pw.document.write('</body></html>');
        pw.document.close();
        pw.print();
        pw.close();
    }

    const conditions = [
        {
            title: "Վարկավորման ընդհանուր պայմաններ",
            id: 1,
        },
        {
            title: "Վարկային պայմանագրի կնքումը",
            id: 2,
        },
        {
            title: "Վարկի տրամադրումը",
            id: 3,
        },
        {
            title: "Վարկի մարումը",
            id: 4,
        },
        {
            title: "Վարկի վաղաժամկետ մարումը",
            id: 5,
        },
        {
            title: "Ապառիկ վարկավորման առանձնահատկությունները",
            id: 6,
        },
        {
            title: "Վարկային պայմանագրի կատարումն ապահովող գրավի պարտավորության պայմանները",
            id: 7,
        },
        {
            title: "Կողմերի պատասխանատվությունը",
            id: 8,
        },
        {
            title: "Այլ պայմաններ",
            id: 9,
        }
    ]

    const handleSubmit = () => {
        if (checkList.every((item) => item.checked)) {
            setIsDisabled(true)
            setTimeout(() => setIsDisabled(false), 10000);
            setError("")
            let userInfo = LoanFormService.getFormData("userInfo");
            LoanFormService.send().then((data) => {
                }
            )
                .catch((error) => console.log(error))
            setTimeout(() => {
                window.popupSetter({
                    open: true,
                    body: <ConfirmSMSPopup phone={userInfo['mob_phone']}/>,
                    popupClassContainer: 'popup__container--sm',
                    popupClassInfo: "popup__container__info--sm"
            })

            },3000)
        } else {
            scrollDown()
            setError("Նշեք բոլոր վանդակները")
        }
        localStorage.setItem("branch",null)
    }

    const toggle = (id) => {
        checkList.map((item) => {
            if (item.id === id) {
                return item.checked = !item.checked
            }
        })
    }
    return (
        <>
            <div className='fifthStep'>
                <div className='fifthStep__content' id='fifthStep__content'>
                    <nav id="nav">
                        {
                            conditions.map((navitem) => {
                                return <ul key={navitem.id + 1}>
                                    <li className={`${active === navitem.id ? "termsActive" : ` `} text-gray-xs text-gray-xs--md`}
                                        onClick={() => {
                                            scrollToSection(navitem.id)
                                        }}>{navitem.title}</li>
                                </ul>
                            })
                        }
                    </nav>
                    <div className={'fifthStep__content__withScroll'} id={"content"} onScroll={() => contentScroll()}>
                        <div className='fifthStep__content__top' id={"fifthStep__content__top"}>
                            <span id={"i"}>Ծանոթացեք վարկավորման պայմաններին և հաստատեք</span>
                            {!isMobile &&
                                <div className='print' onClick={() => printDocument("fifthStep__content__info")}>
                                    <img src={printer} alt="printer icon"/>
                                    <span>Տպել</span>
                                </div>}
                        </div>
                        <div className='fifthStep__content__info'
                             dangerouslySetInnerHTML={{__html: text}}></div>
                        <button className="popup__downArrow"
                                onClick={() => scrollDown()}>
                            <img src={arrow} alt="arrow"/>
                        </button>
                        <div className='fifthStep__content__bottom text-justify'>
                            <p className='redText'>Համաձայն եմ</p>
                            <ul className='text-gray-xs text-gray-xs--md'>
                                <li>«ԱՔՌԱ Քրեդիտ Ռեփորթինգ» ՓԲԸ-ն «Կրեդո Ֆինանս» ՈՒՎԿ ՓԲԸ-ին տրամադրի իմ անցյալ, ներկա և
                                    ապագա ֆինանսական պարտավորությունների վերաբերյալ տեղեկություններ:
                                </li>
                                <li>«Նորք» սոցիալական ծառայությունների տեխնոլոգիական և իրազեկման կենտրոն հիմնադրամը
                                    «Կրեդո Ֆինանս» ՈՒՎԿ ՓԲԸ-ին տրամադրի իմ անցյալ, ներկա և ապագա աշխատանքային
                                    գործունեության և այլ տվյալների վերաբերյալ տեղեկություններ:
                                </li>
                                <li>«Կրեդո Ֆինանս» ՈՒՎԿ ՓԲԸ-ն կատարի հարցում «Կրեդո Ֆինանս» ՈՒՎԿ ՓԲԸ-ի հետ համագործակցող
                                    ֆինանսական սպասարկման ոլորտում հաճախորդների վերաբերյալ տվյալներ մշակող այլ անձանց,
                                    հանրային տվյալների բազաներին:
                                </li>
                                <li>«Կրեդո Ֆինանս» ՈՒՎԿ ՓԲԸ-ն իմ կողմից տրամադրված էլեկտրոնային փոստին և/կամ բջջային
                                    հեռախոսահամարին՝ էլեկտրոնային նամակի և/կամ SMS հաղորդագրության միջոցով տրամադրի
                                    ցանկացած տեղեկատվություն իմ վարկի, ինչպես նաև «Կրեդո Ֆինանս» ՈՒՎԿ ՓԲ-ի կողմից
                                    առաջարկվող պրոդուկտների և ծառայությունների վերաբերյալ:
                                </li>

                            </ul>
                            <p className='text-gray-xs text-gray-xs--md'>Տեղեկացված եմ և սույնով հաստատում եմ, որ
                                վարկային հարցումների քանակը կարող է նվազեցնել իմ վարկային ամփոփ գնահատականը:</p>
                            <p className='text-gray-xs text-gray-xs--md'>Սույնով հայտնում եմ նաև, որ էլեկտրոնային
                                ընթացակարգով իմ կողմից տրված համաձայնությունը մշակելու իմ անձնական տվյալները,
                                հավասարեցված է, համարժեք է գրավոր համաձայնությանը, ուստի սույնով ես հրաժարվում եմ այս
                                կապակցությամբ հետագայում որևէ բողոք ներկայացնելուց։</p>
                        </div>

                        <div className='fifthStep__content__checkboxes'>
                            {error && <span className="inputError" role="alert">{error}</span>}
                            {checkList.map((option, index) => {
                                return (
                                    <div key={index + 5}>
                                        <label
                                            className="containerCheck text-gray-xs text-gray-xs--md checkItem">{option.value}
                                            <input type="checkbox" onChange={() => toggle(option.id)}/>
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>);
                            })}
                        </div>
                    </div>
                </div>
                <div className='popup-bottom'>
                <span className='text-blue-sm text-blue-sm--pointer text-blue-sm--gray'
                      onClick={() => props.closePopup()}>Չեղարկել </span>
                    <div>
                        {isMobile ?
                            <button type={"button"} className='main-button--mobBlue mobileBtnWhiteBlue' onClick={() => {
                                props.setCurrentStep(4)
                            }}/> :
                            <button type={"button"}
                                    className='main-button main-button--xl-md popup-bottom__buttonBack text-blue-sm text-blue-sm--pointer'
                                    onClick={() => {
                                        props.setCurrentStep(4)
                                    }}>Նախորդ</button>}
                        {isMobile ?
                            <button onClick={() => handleSubmit()} disabled={isDisabled}
                                    className='main-button--mobile mobileBtnGradient'/>
                            :
                            <button onClick={() => handleSubmit()} disabled={isDisabled}
                                    className='main-button main-button--xl-md main-button--gradient popup-bottom__button'>Շարունակել</button>}
                    </div>
                </div>
            </div>
        </>
    )
}
