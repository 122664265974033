import {Api} from "../libs/Api";
import LoanExistsPopup from "../components/LoanExistsPopup";

export default async function checkExistingLoan() {
    return await Api.get('profile/check-uncompleted-loans').then((data) => {
        if (data.hasOwnProperty("formError")) {
            window.popupSetter({
                open: true,
                body: <LoanExistsPopup data={data} endDate={data.formError.time}/>,
                popupClassContainer: 'popup__container--sm',
                popupClassInfo: "popup__container__info--sm"
            })
            return true;
        }
        return false;
    })
        .catch((error) => {
            return window.popupSetter({
                open: true,
                body: <LoanExistsPopup data={error}/>,
                popupClassContainer: 'popup__container--sm',
                popupClassInfo: "popup__container__info--sm"
            })
            // return console.log(error)
        })
}
