import moment from "moment/moment";

export var validatorRequired = {
    required: "Պարտադիր է լրացնել։"
}
export var validatorHayeren = {
    pattern: {
        value: /^([Ա-Ֆա-ֆև\- ]{1,})$/gi,
        message: "Լրացնել հայատառ։",
    },
}
export var validatorPhone = {
    pattern: {
        value: /^(\+374|374|0)?(91|93|94|95|96|97|98|99|55|77|41|45|43|44|33){1}[0-9]{6}$/gi,
        message: "Հեռախոսահամարի ֆորմատը սխալ է։",
    },
}
export var validatorEmail = {
    pattern: {
        value: /^\S+@\S+\.\S+$/,
        message: "Էլ․ փոստի ֆորմատը սխալ է։",
    },
}
export var validatorSocCard = {
    pattern: {
        value: /^[0-9]{10}$/,
        message: "Սոց քարտը պետք է լինի 10 նիշ։",
    },
}

export function validateDate(date, hookData = null, format = 'DD/MM/YYYY') {
    let _return = false;
    if (date.indexOf('_') === -1) {
        date = moment(date, format);
        if (date.isValid()) {
            _return = date
        }
    }
    if (hookData) {
        if (_return) {
            hookData.clearErrors('_' + hookData.key);
        } else {
            hookData.setError('_' + hookData.key, {type: 'format', 'message': 'Սխալ ամսաթիվ։'});
        }
    }
    return _return;
}
